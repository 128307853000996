@import 'mixins';
@import 'functions';
@import 'variables';

input {
  -webkit-appearance: none;
  border: none;
  border-radius: $border-radius-square;
  box-sizing: border-box;
  font: $paragraph-16-reg;
  height: $space-6;
  padding: $space-2;
  transition: border 500ms ease-in-out;
  width: 100%;

  &::placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }

    font: $paragraph-16-reg;
  }

  &:disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }

    cursor: default;
  }

  &:hover {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }

  &:focus {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }
}

textarea {
  -webkit-appearance: none;
  border-radius: $border-radius-square;
  box-sizing: border-box;
  font: $paragraph-16-reg;
  resize: none;
  transition: border 500ms ease-in-out;
  width: 100%;

  &::placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }

    font: $paragraph-16-reg;
  }

  &:hover {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }

  &:focus {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }
}

select {
  @include themify() {
    background: themed('surfacePrimary');
  }

  font: $paragraph-16-reg; // NOTE: need to be set at 16px or else mobile will zoom in
  padding-left: $space-2;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/**
 * Stripe Styles
 */
.StripeElement {
  @include themify() {
    background: themed('surfacePrimary');
    border: 1px solid themed('borderPrimary');
  }

  border-radius: $border-radius-small;
  box-sizing: border-box;
  height: $space-7;
  padding: $space-2 14px;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  select {
    font: $paragraph-14-reg;
  }
}
