@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.centeredTitle {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
  padding: 0 $space-2 $space-3;
  letter-spacing: $letter-spacing-headline-24;
  text-align: center;
}

.stickerContainer {
  @include themify() {
    border: 1px solid themed('borderTertiary');
  }

  border-radius: $border-radius;
  display: flex;
  margin-bottom: $space-5;
  padding: $space-3 0;
  justify-content: center;
}

.stickerSheet {
  display: flex;
  flex-wrap: wrap;
  gap: $space-1;
  justify-content: center;
  width: 272px;

  > svg {
    height: $space-6;
    width: $space-6;
  }
}

.fiveStickers {
  width: 160px;
}

.eightStickers {
  width: 224px;
}

.contentDivider {
  @include themify() {
    border-bottom: 1px solid themed('borderTertiary');
  }

  font: $caption-12-med;
  letter-spacing: 1.2px;
  padding-bottom: $space-2;
  text-align: center;
  text-transform: uppercase;
}

.item {
  align-items: center;
  display: flex;
  font: $paragraph-14-reg;
  padding-top: $space-2;
}

.sticker {
  display: flex;
  height: $space-6;
  padding-right: $space-2;
  width: $space-6;
}

.modalButton {
  margin: $space-5 0;
}

.terms {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $label-12-reg;
  text-align: center;
}

.termsLink {
  @include themify() {
    color: themed('contentSecondary');
  }

  cursor: pointer;
  display: inline;
  font: $label-12-reg;
  text-decoration: underline;
}

@media (min-width: 414px) {
  .stickerSheet {
    gap: $space-2;
    width: 304px;
  }

  .fiveStickers {
    width: 176px;
  }

  .eightStickers {
    width: 240px;
  }
}

@media (min-width: 460px) {
  .stickerSheet {
    width: 344px;

    > svg {
      height: $space-7;
      width: $space-7;
    }
  }

  .fiveStickers {
    width: 206px;
  }

  .eightStickers {
    width: 272px;
  }
}
