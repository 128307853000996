@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Order Toggle Component Styles*/
/* ---------------------------------*/

.orderToggle {
  @include themify() {
    border-bottom: 1px solid themed('borderPrimary');
  }

  align-items: center;
  display: flex;
  font: $label-16-semi;
  padding: $space-3;
}

.icon {
  transform: rotate(0);

  g {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.expand {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  padding-right: 0;
  text-decoration: none;
}

.isExpanded {
  transform: rotate(180deg);
}

.orderHeader {
  @include themify() {
    color: themed('contentPrimary');
  }

  flex: 1 0 auto;
  font: $label-16-semi;
  letter-spacing: $letter-spacing-large;
  text-transform: capitalize;
}
