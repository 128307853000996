@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  @include themify() {
    background-color: themed('surfaceAccentPrimary');
  }

  border-radius: $border-radius-square;
  margin-bottom: $space-3;
  width: 100%;
}

.memberType {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  margin-bottom: $space-half;
}

.validThru {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-12-reg;
  letter-spacing: 0;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  padding: $space-2;

  &.withButton {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}

.button {
  font: $label-12-reg;
}

@media (min-width: $screen-sm-min) {
  .wrapper {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
}
