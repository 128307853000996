@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* RewardsBanner styles*/
.promoDescriptionWrapper {
  margin-left: $space-2;
}

.rewardDescription {
  @include themify() {
    color: themed('contentPrimary');
  }

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font: $paragraph-14-semi;
  line-height: 20px;
  overflow: hidden;
}

.stickerOrProgress {
  align-items: center;
  display: flex;
}

.learnMoreBtn {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-semi;
  padding-top: $space-half;
  text-decoration: underline;
  white-space: nowrap;
}

.takeoutSticker {
  height: $space-6;
  width: $space-6;

  > svg {
    height: $space-6;
    width: $space-6;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .promoDescriptionWrapper {
    align-content: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .rewardDescription {
    display: block;
    font: $paragraph-16-semi;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .learnMoreBtn {
    font: $paragraph-16-semi;
    padding-top: 0;
  }
}
