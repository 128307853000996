@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.scrollableContent {
  flex-grow: 1;
  margin-top: $space-3;
}

section {
  margin-bottom: $space-3;
}

.reorderWrapper {
  bottom: 0;
  margin: 0 (-$space-2); /* offset map grid padding for full width button*/
  position: sticky;
}

.mapWrapper {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }
}

.underline {
  text-decoration: underline;
}

@media (min-width: $screen-sm-min) {
  .mapContainer {
    height: calc(100vh - 80px);
    overflow: hidden;

    &.withCurbside {
      height: calc(100vh - 80px - 52px); /* navbar + curbside banner*/
    }
  }

  .mapContent {
    @include themify() {
      border-left: solid 1px themed('borderPrimary');
    }

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .reorderWrapper {
    margin: 0 (-$space-3); /* offset map grid padding for full width button*/
  }
}

.toastWrapper {
  position: absolute;
  bottom: 0;
  right: 100px;
  width: 168px;
}
