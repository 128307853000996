@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.buttonWrapper {
  display: flex;
  padding: $space-2;
  width: calc(100% - $space-4);
}

.adjustTipCta {
  @include themify() {
    box-shadow: 0 -1px 0 0 themed('surfaceSecondary');
  }

  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.info {
  display: flex;
  padding: $space-2 $space-2 0;

  .icon {
    padding-right: $space-1;
  }

  .description {
    @include themify() {
      color: themed('contentPrimary');
    }

    font: $label-12-reg;
  }
}

.adjustTipBtn {
  margin-right: $space-half;
}

.reorderBtn {
  margin-left: $space-half;
}

.tipText {
  @include themify() {
    color: themed('contentPrimary') !important; /*override component libary*/
  }
}
