@import '~styles/variables';
@import '~styles/mixins';
@import '~styles/functions';

.buttonLink {
  @include themify() {
    color: themed('contentPrimary');
  }

  background-color: transparent;
  border: none;
  cursor: pointer;
  font: $label-16-reg;
  padding: 0;
  text-decoration: underline;

  &:hover {
    @include themify() {
      color: themed('contentActive');
    }
  }

  &.focus-visible {
    @include themify() {
      outline: 1px dashed themed('borderTertiary');
    }

    outline-offset: 2px;
  }

  &:focus-visible {
    @include themify() {
      outline: 1px dashed themed('borderTertiary');
    }

    outline-offset: 2px;
  }

  &.large {
    font: $label-20-reg;
  }

  &.small {
    font: $label-14-reg;
  }

  &.xsmall {
    font: $label-12-reg;
  }

  &.disabled {
    @include themify() {
      color: themed('contentTertiary');
    }

    cursor: auto;

    cursor: initial;
  }

  &.linkSecondary {
    @include themify() {
      color: themed('contentSecondary');
    }

    &:hover {
      @include themify() {
        color: themed('contentActive');
      }
    }

    &.focus-visible {
      @include themify() {
        outline-color: themed('borderTertiary');
      }
    }

    &:focus-visible {
      @include themify() {
        outline-color: themed('borderTertiary');
      }
    }
  }
}
