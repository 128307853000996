@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Vehicle Select Component Styles*/
/* ---------------------------------*/
.sectionTitle {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;
  font: $label-14-semi;
  justify-content: space-between;
  margin-bottom: $space-3;
}

.topSelect {
  margin-bottom: $space-3;
}
