@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.card {
  @include themify() {
    border: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  background: none;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  margin-top: $space-5;
  padding: $space-3 $space-2;
}

.sticker {
  align-items: center;
  display: flex;
  margin-right: $space-2;
}

.copyWrapper {
  align-items: center;
  display: flex;
}

.copy {
  display: inline-block;
  font: $paragraph-14-reg;
}

.switch {
  align-items: center;
  display: flex;
  margin-left: $space-2;
}

.congratsCopy {
  font: $paragraph-14-semi;
}
