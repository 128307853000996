@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Account Container styles*/
/* ---------------------------------*/

.accountWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.header {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  padding: $space-5 0;
}

.heading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
  white-space: nowrap;
}

.section {
  margin-top: $space-7;
}

.sectionHeading {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  padding-bottom: $space-3;
}

.sectionContent {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
  }

  display: flex;
  justify-content: space-between;
  padding: $space-3 0;
  width: 100%;
}

.label {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $label-14-reg;
  margin-bottom: $space-1;
}

.labelDetails {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;

  &.disabled {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &.extraPadding {
    font: $label-14-reg;
    margin-bottom: $space-1;
  }
}

.bold {
  font: $label-14-semi;
}

.labelSubdetails {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $paragraph-14-italic;
  margin-top: $space-1;
}

.editButton {
  align-self: flex-start;
  display: flex;
  font: $label-14-reg !important; /* to override component library*/
  margin-left: $space-2;
}

.renewButton {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-self: flex-start;
  display: flex;
  font: $label-14-reg;
  margin-left: $space-2;

  &:hover {
    @include themify() {
      color: themed('contentActive');
    }
  }
}

.addButton {
  width: 120px;
}

.addressWrapper,
.paymentsWrapper {
  padding-bottom: $space-3;
}

.address {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
}

.deliveryInstructions {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $paragraph-14-italic;
  margin-top: $space-1;
}

.cardInfo {
  align-items: center;
  display: flex;
}

.cardError {
  color: $content-caution;
  margin-left: $space-half;
}

.switch {
  margin-left: $space-2;
}

.fixedSpinner {
  > div {
    position: fixed !important;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .section {
    margin-top: $space-10;
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .heading {
    font: $headline-40-med;
  }

  .header {
    padding: $space-7 0;
  }
}
