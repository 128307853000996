@import 'mixins';
@import 'functions';
@import 'variables';

// Application Styles
// ---------------------------------
html,
body,
#root,
.appContainer {
  height: 100%;
}

li {
  list-style: none;
}

button {
  cursor: pointer;
}

input,
textarea,
button,
a,
div,
span,
li,
select,
.btn {
  &:focus {
    outline: none;
  }

  &.focus-visible {
    @include themify() {
      outline: themed('borderTertiary') dashed 1px;
    }

    outline-offset: 2px;
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

body {
  background: $neutral-1;
}

.ReactModal__Body--open,
.dropdown-open,
.lock-scroll {
  // Don't allow scroll behind modals, menus or category dropdown
  height: 100%;
  overflow: hidden;
  position: relative;
}

// overflow: hidden gets ignored on html and body for mobile browsers that parse <meta name="viewport">
// so we have to apply this to a div within html and body for it to not allow scrolling behind
// modals on mobile view
.ReactModal__Body--open #root {
  overflow: hidden;
  position: relative;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0);
}

.ReactModal__Content {
  overflow-y: visible;
}

// FIXME: better way than this?
.bm-burger-button {
  display: none;
}

// Tooltip styles
[role='tooltip'] {
  display: none;
}

button:hover + [role='tooltip'],
button:focus + [role='tooltip'] {
  display: block;
}

// Branch Banner Styles
// ---------------------------------

// Needed to make sure the banner iframe isn't displayed on top
// of any modals. The default was z-index: 9999
#branch-banner-iframe {
  z-index: 5 !important;
}

// fixes bug when modal opens and banner jumps down 76 pixels while modal opens.
.branch-banner-is-active.ReactModal__Body--open {
  margin-top: 0 !important;
  transition: none !important;
}
