/* stylelint-disable no-descending-specificity */
@import '~styles/variables';
@import '~styles/mixins';
@import '~styles/functions';

.button {
  align-items: center;
  border: none;
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  font: $label-14-med;
  height: $space-6;
  justify-content: center;
  padding: $space-2 $space-3;
  position: relative;
  text-align: center;
  transition: background-color 0.3s, opacity 0.3s, border-color 0.3s;
  white-space: nowrap;

  &:focus {
    @include themify() {
      outline-color: themed('borderTertiary');
    }

    outline: 1px dashed;
    outline-offset: 2px;
  }

  &.fullWidth {
    width: 100%;
  }

  &.disabled {
    border: none;
    pointer-events: none;
  }

  &.small {
    font: $label-14-med;
    height: $space-5;
    padding: 12px $space-2;
  }
}

.icon {
  height: $icon-small;
  margin-right: $space-1;
  width: $icon-small;
}

.fill {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
    color: themed('contentInversePrimary');
  }

  svg {
    path {
      @include themify() {
        fill: themed('contentInversePrimary');
      }
    }
  }

  &:hover {
    @include themify() {
      background-color: themed('surfaceActive');
    }
  }

  &.disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }

    svg {
      path {
        @include themify() {
          fill: themed('contentSecondary');
        }
      }
    }
  }
}

.inverse {
  @include themify() {
    background-color: themed('surfacePrimary');
    color: themed('contentPrimary');
  }

  svg {
    path {
      @include themify() {
        fill: themed('contentPrimary');
      }
    }
  }

  &:hover {
    @include themify() {
      background-color: themed('surfaceActive');
      color: themed('contentInversePrimary');
    }

    svg {
      path {
        @include themify() {
          fill: themed('contentInversePrimary');
        }
      }
    }
  }

  &:focus {
    @include themify() {
      outline-color: themed('borderInverseTertiary');
    }
  }

  &.disabled {
    @include themify() {
      background-color: themed('surfaceInverseSecondary');
      color: themed('contentInverseSecondary');
    }

    svg {
      path {
        @include themify() {
          fill: themed('contentInverseSecondary');
        }
      }
    }
  }
}

.outline {
  @include themify() {
    background-color: transparent;
    border: 1px solid themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-14-reg;

  svg {
    path {
      @include themify() {
        fill: themed('contentPrimary');
      }
    }
  }

  &:hover {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }

  &.disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }

    svg {
      path {
        @include themify() {
          fill: themed('contentSecondary');
        }
      }
    }
  }
}

.caution {
  @include themify() {
    background-color: transparent;
    border: 1px solid $border-caution;
    color: $content-caution;
  }

  font: $label-14-reg;

  svg {
    path {
      @include themify() {
        fill: $content-caution;
      }
    }
  }

  &:hover {
    @include themify() {
      background-color: $surface-caution;
    }
  }

  &.disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      border-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }

    svg {
      path {
        @include themify() {
          fill: themed('contentSecondary');
        }
      }
    }
  }
}
