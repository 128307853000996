@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.radioWrapper {
  align-items: center;
  display: flex;
  margin-bottom: $space-3;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.card {
  margin-left: $space-3;
}

.icon {
  margin-right: $space-1;
}

.noCardLabel {
  align-items: center;
  display: flex;
  font: $paragraph-14-reg;
  margin-left: $space-3;

  /* remove with close of multiple payment ff*/
  &.noPadding {
    margin-left: 0;

    .icon {
      margin-right: $space-2;
    }
  }
}

/* remove with close of multiple payment ff*/
.extraPadding > img {
  margin-right: $space-2;
}

.mockRadioBtn {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: 100%;
  background-color: transparent;
  height: 22px;
  margin: 2px;
  padding: 0;
  width: 22px;
}

.socialOption {
  align-items: center;
  display: flex;
}

.express {
  @include themify() {
    color: themed('contentAccent');
  }

  align-items: center;
  display: flex;
  font: $paragraph-14-reg;
  margin-left: $space-1;
}

.lightning path {
  @include themify() {
    fill: themed('contentAccent');
    stroke: themed('contentAccent');
  }
}

.loginLink {
  margin-left: $space-1;
}
