@import '~styles/variables';

/* Terms Styles*/
.terms {
  font-size: $label-12-reg;
  padding: $space-3 0 0;
  text-align: center;
}

.link {
  display: inline;
}
