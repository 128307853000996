@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Payment Modal Component Styles*/
/* ---------------------------------*/

.radioWrapper > div {
  width: 100%;
}

.modalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /* FIXME: have to nest because of component library issues CN-23127*/
  .cardSelectionList {
    @include themify() {
      border-bottom: solid 1px themed('borderPrimary');
    }

    grid-template-columns: repeat(3, [col-start] 1fr);
    margin: $space-2 0;
    padding-bottom: $space-5;

    &.fixedHeight {
      .radioWrapper > div {
        height: 102px;
      }
    }

    &.noBorder {
      border: none;
      padding-bottom: 0;
    }
  }
}

.sublabelError {
  color: $content-caution;
  display: block;
  white-space: nowrap;
}

.cardInfo {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
}

.addForm,
.cardInfo {
  padding-bottom: $space-2;
}

.expiryInfo {
  display: flex;
  margin-bottom: $space-3;
}

.btnRemove {
  font: $label-14-reg !important; /* to override component library*/
}

.btnWrapper {
  background-color: $white;
  bottom: 0;
  padding: $space-2 0;
  position: fixed;
  width: calc(100% - #{$space-4});
  z-index: 2; /* put button on top of radio*/

  &::before {
    @include themify() {
      background-color: themed('borderPrimary');
    }

    top: 0;
    content: '';
    height: 1px;
    margin-left: -$space-2;
    position: absolute;
    width: 100vw;
  }
}

.iconLock {
  margin-right: $space-1;

  g path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .modalContent {
    /* FIXME: have to nest because of component library issues CN-23127*/
    .cardSelectionList {
      gap: $space-2;
    }
  }
}
