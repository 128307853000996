@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.managedDelivery {
  padding: $space-3;
}

.header {
  padding-bottom: $space-2;
}

.trackingLink {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  text-decoration: underline !important; /*override component styling*/
}

.infoText {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $paragraph-14-reg;
}
