@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.modalfooter {
  margin-top: $space-3;

  &.fixed {
    @include themify() {
      background-color: themed('surfacePrimary');
    }

    bottom: 0;
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.16);
    margin-left: -$space-2;
    margin-right: -$space-2;
    padding: $space-2;
    position: sticky;
  }
}

.message {
  margin-bottom: $space-2;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $grid-breakpoint-m) {
  .modalfooter {
    &.fixed {
      margin-left: -$space-5;
      margin-right: -$space-5;
    }
  }
}
