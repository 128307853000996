@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Menu Item Styles*/
/* ---------------------------------*/
.imageContainer {
  display: flex;
  height: $thumbnail-width;
  width: $thumbnail-width;
}

.itemContainer {
  @include themify() {
    border: 1px solid themed('borderPrimary');
  }

  border-radius: $border-radius;
  cursor: pointer;
  height: $thumbnail-width;
  margin: 0 0 $space-3;
  overflow: hidden;
  position: relative;

  &.hidden {
    height: 0;
    opacity: 0;
    transition: all 0.05s;
  }
}

.itemContent {
  display: flex;
  height: $thumbnail-width;
}

.itemText {
  display: flex;
  flex-direction: column;
  padding: $space-3;
  width: 100%;

  &.withThumbnail {
    padding: $space-3 $space-2 $space-3 $space-3;
    width: calc(100% - ((#{$space-2} * 2) + #{$thumbnail-width}));
  }
}

.name {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  letter-spacing: $letter-spacing-large;
  max-width: 300px;
  overflow: hidden;
  padding: 0 0 $space-1 0;
  text-overflow: ellipsis;
  transition: color 0.2s;
  white-space: nowrap;
}

.small {
  height: 159px;
  margin: 0;
  min-width: 254px;
  padding: 0;
  width: 254px;

  .imageContainer {
    height: $thumbnail-width-carousel;
    width: $thumbnail-width-carousel;

    &.hidden {
      height: 0;
      opacity: 0;
    }
  }

  .itemContent {
    flex-wrap: wrap;
    padding: $space-3 0 0 0;
  }

  .itemText {
    max-width: calc(100% - #{$space-6});
    padding: 0 $space-3;

    &.withThumbnail {
      width: calc(100% - ((#{$space-4} * 2) + #{$thumbnail-width-carousel}));
      padding-top: 0;
    }
  }

  .name {
    padding-bottom: 4px;
  }
}

.price {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  width: calc(100% - #{$space-4});
}

.orderDate {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $label-12-reg;
}

.plusIcon {
  width: $icon-height;
  height: $icon-height;
  transition: 0.2s;

  path {
    @include themify() {
      fill: themed('contentPrimary');
    }

    transition: 0.2s;
  }

  circle {
    fill: transparent;
    transition: 0.2s;
  }
}

.carouselPrice {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  margin-right: $space-1;
  transition: 0.2s;
}

.success {
  color: $content-success;

  .carouselPrice {
    color: $content-success;
  }
}

.oneClick {
  display: flex;
  padding: $space-1 0 $space-half;
}

.badgeIcon {
  height: $icon-height;
  width: $icon-height;
}

.orderPriceOneClick {
  align-items: center;
  display: flex;
}

.carouselPriceContainer {
  @include themify() {
    border-top: 1px solid themed('borderPrimary');
  }

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto $space-2 0;
  padding: $space-1 0 $space-2 $space-1;
  width: 100%;
}

/* stylelint-disable */
/* disabling style lint for order/properties-order*/
.fadeOut {
  /*changing the order of these attributes will break animation*/
  animation-name: fadeout;
  animation-duration: 0.4s;
  animation-delay: 1.7s;
}

.fadeIn {
  /*changing the order of these attributes will break animation*/
  animation-name: fadein;
  animation-duration: 0.2s;
}
/* stylelint-enable */

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.more {
  text-decoration: underline;
}

.description {
  @include themify() {
    color: themed('contentPrimary');
  }

  -webkit-box-orient: vertical;
  display: -webkit-box;
  font: $paragraph-14-reg;
  -webkit-line-clamp: 2;
  margin-bottom: $space-1;
  overflow: hidden;
  width: 100%;
}

.descriptionSmall {
  margin-bottom: 0;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .itemContainer {
    @include themify() {
      border: 1px solid themed('borderPrimary');
    }

    border-radius: $border-radius;
    height: $thumbnail-width;
    margin: 0 0 $space-3 0;
    min-width: 332px;
    width: 100%;

    &:hover {
      @include themify() {
        background-color: themed('surfaceSecondary');
      }
    }
  }

  .itemText {
    flex-direction: column nowrap;
  }

  .small {
    height: 159px;
    width: 254px;
    margin-bottom: 0;
    min-width: 0;

    .itemText {
      padding: 0 $space-2 0 $space-3;
    }
  }

  .itemContent {
    height: $thumbnail-width;
  }

  .price {
    margin-top: auto;
  }
}

/* large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .itemContainer {
    @include columnGap(2, $space-3);
  }

  .small {
    height: 159px;
    min-width: calc(100% - 2px);
    padding: 0;
    width: calc(100% - 2px);

    .imageContainer {
      height: $thumbnail-width-carousel;
      width: $thumbnail-width-carousel;
    }

    .itemContent {
      flex-wrap: wrap;
      padding: $space-3 0 0 0;
    }

    .itemText {
      padding: 0 $space-3;

      &.withThumbnail {
        width: calc(100% - ((#{$space-4} * 2) + #{$space-9}));
      }
    }

    .name {
      padding-bottom: 4px;
    }
  }
}

/* X-large devices and above (1440px and up) */
@media (min-width: $screen-xl-min) {
  .small {
    height: 159px;
    min-width: 276px;
    padding: 0;
    width: 276px;

    .itemContent {
      flex-wrap: wrap;
      max-width: 276px;
      padding: $space-3 0 0 0;
      width: 276px;
    }
  }
}

@media (hover: hover) {
  .oneClick {
    &:hover {
      .carouselPrice {
        color: $content-success;
      }

      .plusIcon {
        circle {
          @include themify() {
            fill: $content-success;
          }
        }

        path {
          @include themify() {
            fill: themed('surfaceSecondary');
          }
        }

        g path {
          @include themify() {
            fill: $content-success;
          }
        }
      }
    }
  }
}
