@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Bag Modal Styles*/
/* ---------------------------------*/
.modalHeader {
  margin-bottom: 0;
}

.modalContent {
  flex-grow: 1;

  &.withPromoBanner {
    padding-bottom: $space-9;
  }
}

.banner {
  margin-top: $space-3;
}

.badgeCheck {
  margin-right: $space-1;
}

.promoApplied {
  align-items: center;
  color: $content-success;
  display: flex;
  font: $label-12-reg;
  margin-bottom: $space-2;
}

.checkoutBtn {
  margin-bottom: $space-2;
}
