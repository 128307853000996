@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

#totalTip {
  padding: $space-1;
  font: $label-12-reg;

  .totalTipLineItem {
    @include themify() {
      border-color: themed('borderPrimary');
    }

    border-top: 1px solid;
    padding-top: $space-1;
  }
}
