@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.header {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  margin-bottom: $space-2;
}

.addressLine {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  margin-bottom: $space-half;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.outlink {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: block;
  font: $label-14-reg;
  margin-top: $space-2;
  text-decoration: underline !important;

  &:hover {
    @include themify() {
      color: themed('contentPrimary');
    }
  }
}

.phone {
  @include themify() {
    color: themed('contentPrimary');
  }

  text-decoration: none;
}
