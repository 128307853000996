@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  button {
    padding: $space-2 0 !important;
  }
}

.contentWrapper {
  align-items: flex-start;
  color: $black;
  display: flex;
  padding: 16px 0;
  text-decoration: none;

  &:first-of-type {
    padding-top: 0;
  }

  &:active p {
    @include themify() {
      color: themed('contentActive');
    }
  }

  &:active svg path {
    @include themify() {
      fill: themed('contentActive');
    }
  }
}

.call,
.text {
  font: $label-14-semi;
  margin-bottom: $space-half;
}

.innerFlexWrapper {
  display: flex;
}

.supportText {
  @include themify() {
    color: themed('contentActive');
  }

  display: block;
  font: $label-14-reg;
}

.icon {
  width: 24px;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .call,
  .text {
    width: 152px;
  }

  .text {
    margin-top: 0;
  }

  .contentWrapper {
    align-items: center;
    height: 84px;
    justify-content: center;
    padding: 0;
    width: 202px;

    &:hover {
      @include themify() {
        background-color: themed('surfaceAccentPrimary');
      }
    }
  }

  .wrapper {
    margin-top: 0;
  }
}
