@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.btnInfo {
  @include themify() {
    color: themed('contentTertiary');
  }

  align-items: center;
  background: transparent;
  border: none;
  height: inherit;
  letter-spacing: 0;
  padding: 0;
  text-transform: capitalize;
}

.iconInfo {
  height: $space-2;
  padding-left: $space-1;
  width: $space-2;

  g {
    @include themify() {
      color: themed('contentPrimary');
    }
  }
}

.tooltip {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  border-radius: $border-radius-small;
  bottom: $space-3;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  left: 50%;
  letter-spacing: 0;
  padding: 12px;
  position: absolute;
  transform: translateX(-45%);
  width: 274px;
}

.tooltipContainer {
  display: flex;
  position: relative;

  &:hover {
    @include themify() {
      color: themed('contentActive');
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .tooltip {
    left: 10px;
    transform: none;
    transform: initial;
  }
}
