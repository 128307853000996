@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.searchWrapper {
  @include themify() {
    background-color: themed('surfaceSecondary');
    color: themed('contentPrimary');
  }

  border-radius: $border-radius;
  padding: $space-5 $space-3;
  text-align: center;
}

.searchHeader {
  font: $label-16-semi;
  letter-spacing: 0.2px;
}

.searchSubtext {
  font: $paragraph-14-reg;
  margin: $space-1 0 $space-2;
}
