@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Tip Modal Component Styles*/
/* ---------------------------------*/

.subheading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-20-semi;
  margin: $space-4 0 $space-3;
}

.prompt {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  padding-bottom: $space-1;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .subheading {
    margin-top: $space-7;
  }
}
