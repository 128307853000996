@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.inputWrapper {
  position: relative;
  width: 100%;
}

.label {
  display: block;
  font: $label-14-reg;
  text-align: left;

  &.hideLabel {
    display: none;
  }
}

.input {
  @include themify() {
    color: themed('contentPrimary');
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius-square;
  box-sizing: border-box;
  display: flex;
  font: $paragraph-16-reg;
  height: $space-6;
  margin-top: $space-1;
  padding: 11px $space-2;
  width: 100%;

  &:-ms-input-placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &::placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &[disabled] {
    @include themify() {
      background-color: themed('$surface-tertiary');
      color: themed('contentSecondary');
    }

    &:hover,
    &:focus {
      @include themify() {
        border-color: themed('borderPrimary');
      }
    }
  }

  &.error {
    border-color: $border-caution;
  }

  &.withClearIcon {
    padding: 11px $space-6 11px $space-2;
  }
}

.link {
  align-items: center;
  display: flex;
  position: absolute;
  right: $space-2;
  top: 44px;
}

.clearIconWrapper {
  align-items: center;
  display: flex;
  left: calc(100% - 32px);
  position: absolute;
  top: 44px;

  &.withLink {
    left: calc(100% - 72px);
  }

  &.withNoLabel {
    top: $space-2;
  }
}

.clearIcon {
  cursor: pointer;
  height: $icon-height;
  width: $icon-height;

  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }

  &:focus {
    @include themify() {
      outline: 1px dashed themed('contentPrimary');
    }
  }

  &:hover,
  &:active {
    path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}

.errorText {
  color: $content-caution;
  display: block;
  font: $label-12-reg;
  margin-top: $space-1;
  text-align: left;
}
