@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.downloadWrapper {
  @include themify() {
    border-top: solid 1px themed('borderInverseTransparent');
    color: themed('contentInversePrimary');
  }

  padding: $space-3 0;
}

.headline {
  font: $label-14-reg;
}

.button {
  margin-top: $space-3;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .downloadWrapper {
    @include themify() {
      background-color: themed('surfaceInverseSecondary');
    }

    border: none;
    border-radius: $border-radius;
    margin-top: $space-3;
    padding: $space-3 $space-2;
  }

  .headline {
    align-items: center;
    display: flex;
    font: $label-14-med;
  }

  .iconPhone {
    margin-right: $space-half;
  }

  .qrWrapper {
    align-items: center;
    display: flex;
  }

  .qrCode {
    height: 100px;
    margin-right: $space-3;
    width: 100px;
  }

  .qrHead {
    font: $paragraph-14-semi;
    margin-bottom: $space-1;
  }

  .qrCopy {
    font: $paragraph-14-reg;
  }
}
