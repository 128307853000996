@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Date/Time Picker Component Styles*/
/* ---------------------------------*/
.dateTimePicker {
  display: flex;
  flex-direction: column;
}

.pickerType {
  margin-bottom: $space-3;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }
}
