@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

$desktop-address-line-offset: 20px;

@mixin dot {
  @include themify() {
    background-color: themed('contentPrimary');
  }

  border-radius: 50%;
  content: '';
  display: inline-block;
  height: $space-half;
  margin: 0 $space-1;
  transform: translateY(-50%);
  width: $space-half;
}

.iconsWrapper {
  margin-top: $space-2;
}

.imageContainer {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
}

.image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.contentWrapper {
  padding: $space-5 0 $space-3 0;
}

.restaurantContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 $space-2;
}

.restaurantName {
  @include themify() {
    color: themed('contentPrimary');
  }

  -webkit-box-orient: vertical;
  display: -webkit-box;
  font: $headline-32-med;
  -webkit-line-clamp: 4;
  letter-spacing: -0.4px;
  overflow: hidden;
  width: 100%;
}

.restaurantInfoWrapper {
  display: flex;
  margin-top: $space-1;
  width: 100%;
}

.restaurantActions {
  margin-left: auto; /* move all the way to the right of the flexbox container*/
}

.saveButton {
  margin-top: -12px; /* center aligns with the text content*/
}

.restaurantInfo {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: flex;
  font: $label-14-reg;
  max-width: calc(100% - #{$space-9});
}

.address {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeTime {
  display: none;
}

.hoursButton {
  @include themify() {
    color: themed('contentPrimary');
  }

  background-color: transparent;
  border: none;
  display: inline-block;
  font: $label-14-reg;
  padding: 0 0 0 $space-1;
  text-decoration: underline;
  min-width: 75px; /* prevents cutting to multiple lines on mobile*/

  &.hasSaveButton {
    margin-bottom: 15px;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .wrapper {
    @include themify() {
      background-color: themed('surfaceSecondary');
    }
  }

  .contentWrapper {
    margin: auto;
    max-width: $app-container-width;
    padding: $space-5 0;
  }

  .restaurantContent {
    display: block;
    height: 100%;
    margin: 0 $space-4;
  }

  .restaurantName {
    -webkit-line-clamp: 3;
    margin: 0 $space-7 $space-3 0;
  }

  .restaurantInfoWrapper {
    display: flex;
    grid-column-end: span 5;
    margin-top: 0;
  }

  .restaurantInfo {
    display: flex;
    margin: 0;
    width: auto;
    height: $space-2;
  }

  .restaurantActions {
    margin-bottom: 0;
  }

  .address {
    display: block;
    margin-right: auto;
    max-width: 100%;
  }

  .closeTime {
    display: block;
    margin-bottom: $space-1;
    margin-right: auto;
  }

  .dot::before {
    @include dot;
  }

  .hoursButton {
    display: block;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;

    &:hover {
      @include themify() {
        color: themed('contentActive');
      }
    }
  }

  .withImage {
    display: flex;
    flex-direction: row-reverse;

    .imageContainer {
      height: 300px;
      width: 50vw;
    }

    .contentWrapper {
      display: flex;
      margin: 0 0 0 $space-4;
      padding: 0 0 $space-4 0;
      width: 50vw;
    }

    .restaurantContent {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 0;
      height: auto;
      justify-content: flex-end;
      margin: 0 $space-4 0 0;
      max-width: calc(100% - #{$space-4});
      width: 100%;
    }

    .restaurantName {
      -webkit-line-clamp: 4;
    }

    .restaurantInfoWrapper {
      margin-top: 0;
      width: 100%;
    }

    .restaurantInfo {
      display: flex;
      padding-top: 0;
      width: 70%;
    }

    .restaurantActions {
      width: 30%;
    }

    .saveButton {
      margin-left: auto;
      margin-top: -10px;
    }

    .address {
      display: inline-block;
      margin-right: $space-1;
    }

    .closeTime {
      display: none;
    }

    .hoursButton {
      margin-bottom: 0;
    }

    .dot::before {
      display: none;
    }
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .contentWrapper {
    padding: $space-7 0;
  }

  .hoursButton {
    margin-top: 0;
    margin-bottom: -$space-half;
    font: $label-16-reg;
  }

  .restaurantContent {
    margin: 0 $space-7;

    &.fullWidth {
      display: block;
    }
  }

  .restaurantName {
    font: $headline-48-med;
    letter-spacing: -1px;
    margin-bottom: 0;
  }

  .restaurantInfoWrapper {
    margin-top: $space-3;
  }

  .restaurantInfo {
    display: flex;
    font: $label-16-reg;
  }

  .closeTime {
    display: block;
  }

  .address {
    padding-bottom: $desktop-address-line-offset;
  }

  .withImage {
    .imageContainer {
      height: 420px;
    }

    .contentWrapper {
      margin: 0 0 0 $space-7;
      padding: 0 0 $space-7 0;
      width: 664px;
    }

    .restaurantContent {
      margin: 0 $space-7 0 0;
      max-width: calc(100% - #{$space-7});
    }

    .restaurantInfoWrapper {
      @include themify() {
        border-top: solid 1px themed('orderTertiary');
      }

      margin-top: $space-3;
      width: 100%;
    }

    .restaurantInfo {
      max-width: 100%;
      padding-top: $space-3;
      width: 78%;
    }

    .restaurantActions {
      padding-top: $space-3;
      width: 22%;
    }

    .address {
      margin-right: 0;
      flex: 0 1 auto;
    }

    .closeTime {
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      margin-right: 0;
    }

    .hoursButton {
      margin-bottom: 0;
      margin-top: 0;
      white-space: nowrap;
    }

    .closeTime::before {
      @include themify() {
        background-color: themed('contentPrimary');
      }
    }

    .dot::before {
      @include dot;
    }
  }
}
