@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.membershipBadge {
  align-items: center;
  display: flex;
  position: relative;

  svg {
    display: block;
    height: $space-3;
    margin-right: $space-1;
    width: $space-3;
  }

  &.withName {
    @include themify() {
      background-color: themed('surfaceSecondary');
      color: themed('contentPrimary');
    }

    border-radius: $border-radius;
    font: $label-14-med;
    padding: $space-2 $space-3;

    svg {
      margin-right: $space-2;
    }
  }

  &.small {
    svg {
      height: $space-2;
      width: $space-2;
    }
  }
}
