@import '~styles/variables';

/* Default Dropdown Component Styles*/
/* ---------------------------------*/

/* All devices */
.defaultDropdown {
  composes: geosuggest__suggests from '../GeosuggestWrapper/styles.module.scss';

  &:not(.geosuggestHidden) {
    &::after {
      display: none;
    }
  }
}
