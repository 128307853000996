@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.fulfillmentMethods {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  border-radius: $border-radius-round;
  box-sizing: border-box;
  display: flex;
  height: $space-6;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.eta {
  font: $label-12-reg;
}

.toggleOption {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  background: none;
  border: none;
  font: $label-14-reg;
  justify-content: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
  text-transform: capitalize;
  transition: color 0.35s;
  width: 50%;
  z-index: 1;

  &.disabled {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &.active {
    border: none;
    border-radius: $border-radius-round;
    color: $white;
  }
}

.unavailable {
  margin-left: $space-half;
}

.disabled {
  @include themify() {
    color: themed('contentActive');
  }

  cursor: default;
  pointer-events: none;

  &:hover {
    background: $white;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .toggleOption {
    &:hover:not(.active) {
      @include themify() {
        color: themed('contentActive');
      }

      transition: 0.2s;
    }
  }
}
