@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.baseTimer {
  border-radius: 50%;
  position: relative;
  height: 64px;
  margin-bottom: $space-2;
  width: 64px;
}

.baseTimerCircle {
  fill: none;
  stroke: none;
}

.baseTimerPath {
  @include themify() {
    stroke: themed('surfaceInverseSecondary');
  }

  stroke-width: 4px;
}

.baseTimerPathRemaining {
  @include themify() {
    stroke: themed('surfacePrimary');
  }

  stroke-width: 4px;
  stroke-linecap: round;
  transform: scaleX(-1) rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
}

.interiorButton {
  @include themify() {
    background-color: themed('surfacePrimary');
  }

  border-radius: 50%;
  display: flex;
  height: 48px;
  left: $space-1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;

  .iconArrow {
    display: block;
    height: 24px;
    margin: auto;
    width: 24px;

    path {
      fill: $ink-3;
    }
  }

  &:hover {
    @include themify() {
      background-color: themed('surfaceActive');
    }

    .iconArrow path {
      @include themify() {
        fill: themed('surfacePrimary');
      }
    }
  }
}
