@import '~styles/variables';
@import '~styles/mixins';
@import '~styles/functions';

/* Formik Custom Input Component Styles*/
/* ---------------------------------*/
.inputWrapper {
  margin-bottom: $space-3;
}

input.inputError {
  border-color: $border-caution;
}

.inputBox {
  position: relative;

  input {
    /* need to override _forms.scss file as theming does not work on global styling*/
    @include themify() {
      border: solid 1px themed('borderPrimary');
      color: themed('contentPrimary');
    }

    &:-ms-input-placeholder {
      @include themify() {
        color: themed('contentSecondary');
      }
    }

    &::placeholder {
      @include themify() {
        color: themed('contentSecondary');
      }
    }

    &:disabled {
      @include themify() {
        background-color: themed('surfaceTertiary');
        color: themed('contentSecondary');
      }
    }

    &:hover {
      @include themify() {
        border-color: themed('borderTertiary');
      }
    }

    &:focus {
      @include themify() {
        border-color: themed('borderTertiary');
      }
    }

    &.inputError {
      border-color: $border-caution;
    }
  }
}

.cardInput,
.withIcon {
  padding-left: $space-6;
}

.error {
  color: $content-caution;
  font: $label-12-reg;
  margin-top: $space-1;
}

.label {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: block;
  font: $label-14-reg;
  margin-bottom: $space-1;
}

.icon {
  left: $space-2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: $space-3;
}

.clearIcon {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
  position: absolute;
  right: $space-2;
  top: 50%;
  transform: translateY(-50%);

  svg path {
    @include themify() {
      fill: themed('contentPrimary');
    }

    &:hover {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }

  &:hover {
    svg path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}
