@import '~styles/variables';

/* Menu Items Styles*/
/* ---------------------------------*/

.itemsList {
  display: block;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .itemsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: $space-3;
  }
}
