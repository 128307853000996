@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Navbar Component Styles*/
/* ---------------------------------*/
.navbar {
  height: $navbar-height-small;
}

.navWrapper {
  @include themify() {
    border-bottom: 1px solid themed('borderPrimary');
  }

  align-items: center;
  background-color: $white;
  display: flex;
  height: $navbar-height-small;
  justify-content: center;
  top: 0;
  width: 100vw;
  z-index: 4;

  &.sticky {
    position: fixed;
  }

  &.noBorder {
    border-bottom: none;
  }
}

.navContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 $space-2;
  width: 100%;
}

.contentLeft {
  align-items: center;
  display: flex;
  margin-right: $space-2;

  &.short {
    margin-right: $space-7;
  }
}

.logo {
  @include themify() {
    border-right: 1px solid themed('borderPrimary');
  }

  align-items: center;
  display: flex;
  height: $navbar-height-small;
  margin-right: $space-2;
  padding-right: $space-2;

  svg {
    height: 32px;
    width: 29px;
  }
}

.iconBack {
  margin-right: $space-1;

  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.btnBack {
  @include themify() {
    color: themed('contentPrimary');
  }

  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .navbar {
    height: $navbar-height;
  }

  .navWrapper {
    height: $navbar-height;
  }

  .navContent {
    margin: 0 $space-4;
  }

  .contentLeft {
    margin-right: $space-3;
    width: 100%;

    &.short {
      width: 596px;
    }

    &.addressNoWrap {
      overflow: hidden;
    }
  }

  .logo {
    height: $navbar-height;
    margin-right: $space-3;
    padding-right: $space-3;

    svg {
      height: 40px;
      width: 36px;
    }
  }

  .btnBack {
    align-items: center;
    display: flex;
    font: $label-14-reg;
  }
}

/* XLarge devices and above (1440px and up) */
@media (min-width: $app-container-width) {
  .navContent {
    margin: 0 $space-3;
  }

  .contentLeft {
    flex-grow: 1;
    width: auto;
  }

  .btnBack {
    font: $label-16-reg;
  }

  .rightWrap {
    display: flex;
  }
}
