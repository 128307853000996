@import '~styles/variables';
@import '~styles/mixins';
@import '~styles/functions';

.checkboxInput {
  align-items: center;
  display: flex;
}

.label {
  @include themify() {
    color: themed('contentPrimary');
  }

  margin-left: $space-1;
}
