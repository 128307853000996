@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Locations Menu Component Styles*/
/* ---------------------------------*/

.itemName,
.itemAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - #{$space-3});
}

.itemAddress {
  @include themify() {
    color: themed('contentPrimary');
  }

  margin-left: $space-3;
}

.itemName {
  font: $label-14-semi;
}

.icon {
  height: $space-2;
  margin-right: $space-1;
  position: relative;
  top: 1px;
  width: $space-2;
}

.listItem {
  cursor: pointer;
  font: $label-14-reg;
  padding: $space-2;
  transition: color 0.2s;

  &:first-child {
    &::after {
      border-top-left-radius: $border-radius-square;
      border-top-right-radius: $border-radius-square;
    }
  }

  &:last-child {
    &::after {
      border-bottom-left-radius: $border-radius-square;
      border-bottom-right-radius: $border-radius-square;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:hover {
    @include themify() {
      background-color: themed('surfaceSecondary');
    }
  }

  &:focus {
    @include themify() {
      background-color: themed('surfaceSecondary');
    }

    outline: none;
    position: relative;

    &::after {
      @include themify() {
        border: 1px themed('borderTertiary') dashed;
      }

      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &:active {
    @include themify() {
      color: themed('contentActive');
    }

    background-color: transparent;
    outline: none;

    .itemName,
    .itemAddress {
      @include themify() {
        color: themed('contentActive');
      }
    }

    .icon {
      path {
        @include themify() {
          fill: themed('contentActive');
        }
      }
    }

    &::after {
      border: none;
    }
  }
}

.itemNameWrapper {
  display: flex;
  padding-bottom: $space-half;
}

.backButton {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentActive');
  }

  display: flex;
  font: $label-14-reg;
  padding-bottom: $space-2;
  text-decoration: none;
  transition: color 0.2s;
}
