@import 'colors.scss';
@import 'type.scss';

// Variables
// --------------------------------

// Padding
$space-1: 8px;
$space-half: calc($space-1 / 2); // 4px
$space-2: ($space-1 * 2); // 16px
$space-3: ($space-1 * 3); // 24px
$space-4: ($space-1 * 4); // 32px
$space-5: ($space-1 * 5); // 40px
$space-6: ($space-1 * 6); // 48px
$space-7: ($space-1 * 7); // 56px
$space-8: ($space-1 * 8); // 64px
$space-9: ($space-1 * 9); // 72px
$space-10: ($space-1 * 10); // 80px
$space-11: ($space-1 * 11); // 88px
$space-12: ($space-1 * 12); // 96px

// Borders
$border-radius-square: 8px;
$border-radius: 16px;
$border-radius-small: 4px;
$border-radius-round: 40px;

// App
$app-container-width: 1440px;

// Navbar
$navbar-height-small: 56px;
$navbar-height: 72px;
$footer-height: 110px;
$disclaimer-height: 64px;

// Thumbnail
$thumbnail-width: 136px;
$thumbnail-width-carousel: 64px;

//Icons
$icon-x-large: 40px;
$icon-large: 32px;
$icon-default: 24px;
$icon-small: 16px;
$icon-xsmall: 8px;

$icon-height: $icon-small;
$icon-close-height: $icon-default;

// Screen Sizes
// NOTE: 'All devices' defaults are defined as 'xxs' (0px-575px).
$screen-xxs-max: 350px; // xxs
$screen-xs-min: 576px; // xs
$screen-xs-max: 767px; // xs
$screen-sm-min: 768px; // sm
$screen-sm-max: 991px; // sm
$screen-md-min: 992px; // md
$screen-md-mid: 1024px; // md mid
$screen-md-max: 1199px; // md
$screen-lg-min: 1200px; // lg
$screen-xl-min: 1440px; // xl
$screen-xxl-min: 1920px; // xxl

//Grid
$grid-breakpoint-m: 768px;
$grid-breakpoint-l: 1200px;
$grid-breakpoint-max: 1440px;
$grid-max-width: 1328px;

// Z-index
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$z-index-nav: 3;
$z-index-side-menu: 10;
