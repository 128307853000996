@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.outlink {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: flex;
  font: $label-14-reg;
  letter-spacing: 0;

  &:hover,
  &:active {
    @include themify() {
      color: themed('contentActive');
    }

    transition: fill 0.2s ease;
  }

  &:focus,
  &.focus-visible {
    @include themify() {
      outline: thin dashed themed('contentPrimary');
    }
  }

  &:focus,
  &:focus-visible {
    @include themify() {
      outline: thin dashed themed('contentPrimary');
    }
  }
}
