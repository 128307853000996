@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  @include themify() {
    background: themed('surfacePrimary');
    border: 1px solid themed('borderPrimary');
  }

  border-radius: $border-radius;
  font-style: normal;
  padding: 24px;
}
