@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.spinner {
  @include themify() {
    border: 1px solid;
    border-color: themed('loadingInitial')
      rgba(themed('loadingSecondary'), 0.24)
      rgba(themed('loadingSecondary'), 0.24);
  }

  @include animation(rotation 0.75s infinite linear);

  border-radius: 50%;
  display: block;
  position: relative;
}

.centered {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
}

.small {
  height: $icon-small;
  width: $icon-small;
}

.medium {
  height: $icon-default;
  width: $icon-default;
}

.large {
  height: $icon-large;
  width: $icon-large;
}

.x-large {
  @include themify() {
    border-width: $space-half;
  }

  height: $icon-x-large;
  width: $icon-x-large;
}

.overLayActive {
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 10px;
  z-index: 50;
  box-sizing: border-box;
  top: 0;
  width: 100%;
  position: absolute;
}
