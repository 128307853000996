@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.label {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  margin-bottom: $space-1;

  &.disabled {
    @include themify() {
      color: themed('contentSecondary');
    }
  }
}

.select {
  @include themify() {
    background-color: themed('surfacePrimary');
    border: 1px solid themed('borderPrimary');
    color: themed('contentSecondary');
  }

  -webkit-appearance: none;

          appearance: none;
  background-image: url('images/icon-caretdown-16.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - $space-2) center;
  border-radius: $space-1;
  cursor: pointer;
  font: $paragraph-16-reg;
  padding: 11px $space-1 11px $space-2;
  width: 100%;

  &.withValue {
    @include themify() {
      color: themed('contentPrimary');
    }
  }

  &:hover {
    @include themify() {
      outline: thin solid themed('borderTertiary');
    }
  }

  &:focus,
  &.focus-visible {
    @include themify() {
      outline: thin solid themed('borderTertiary');
    }

    background-image: url('images/icon-caretup-16.svg');
  }

  &:focus,
  &:focus-visible {
    @include themify() {
      outline: thin solid themed('borderTertiary');
    }

    background-image: url('images/icon-caretup-16.svg');
  }

  &.disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }

    background-image: none;
    cursor: default;

    &:hover {
      @include themify() {
        outline: none;
      }
    }
  }
}

.helpText {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $label-12-reg;
  margin-top: $space-1;
  width: 100%;
}
