@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.modalFrame {
  @include themify() {
    background-color: themed('surfacePrimary');
    color: themed('contentPrimary');
  }

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font: $paragraph-14-reg;
  height: 100vh;
  overflow: scroll;
  padding: $space-3 $space-2 0;
  width: 100vw;

  & > :first-child {
    margin-right: $space-5;
    padding-bottom: $space-3;
    position: relative;

    &::after {
      @include themify() {
        background-color: themed('borderPrimary');
      }

      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      margin-left: -$space-2;
      position: absolute;
      width: 100vw;
    }
  }
}

.iconWrapper {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: $space-2;
  top: $space-3;
  z-index: 2;
}

.iconClose {
  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $grid-breakpoint-m) {
  .modalFrameWrapper {
    border-radius: 16px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  }

  .modalFrame {
    border-radius: 16px;
    height: auto;
    max-height: calc(100vh - 160px); /* 80px from top and bottom of window*/
    max-width: 460px;
    padding: $space-3 $space-5 0;
    width: calc(100vw - #{$space-4});

    & > :first-child {
      padding-bottom: $space-3;
      position: relative;

      &::after {
        margin-left: -$space-5;
        width: calc(100% + 120px);
      }
    }
  }

  .iconWrapper {
    right: $space-5;
    top: $space-3;
  }
}
