@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Payment Selection Component Styles*/
/* ---------------------------------*/
.paymentWrapper {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.paymentHeader {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  padding: $space-3;
}

.paymentDetailsWrapper {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: $space-3;
}

.paymentOptionsWrapper {
  position: relative;
}

.iconEdit {
  background-color: transparent;
  border: none;
  margin-left: $space-3;
  margin-top: $space-1;
  padding: 0;

  &:hover {
    > svg path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}
