@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  @include themify() {
    color: themed('contentPrimary');
  }

  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-150%);
}

.heading {
  font: $headline-32-med;
  padding-bottom: $space-3;
  text-align: center;
}

.subtext {
  font: $paragraph-16-reg;
  margin: 0 $space-2;
  text-align: center;
}

.link {
  display: inline;
  font: $paragraph-16-reg;
  text-decoration: none;
}

.btn {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
    color: themed('contentInversePrimary');
  }

  align-items: center;
  border: none;
  border-radius: 28px;
  display: flex;
  font: $label-16-med;
  height: $space-7;
  justify-content: center;
  margin: $space-4 auto 0;
  text-decoration: none;
  text-transform: capitalize;
  width: 300px;

  &:hover {
    @include themify() {
      background-color: themed('surfaceActive');
      color: themed('contentInversePrimary');
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .wrapper {
    transform: translateY(-100%);
  }

  .heading {
    font: $headline-48-med;
  }

  .subtext {
    font: $paragraph-20-reg;
    margin: auto;
    max-width: 598px;
  }

  .link {
    font: $paragraph-20-reg;
  }
}
