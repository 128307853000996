@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Restaurant Tip Component Styles*/

.tipStepper {
  @include themify() {
    background-color: themed('surfacePrimary');
    border: solid 1px themed('borderPrimary');
  }

  /* The button's text*/
  & > div {
    border: none;

    &::before {
      content: '$';
    }
  }
}

.card {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: $space-3 $space-2;
  font: $paragraph-14-reg;
  color: $ink-3;

  & > div {
    padding-top: $space-2;
  }

  & > div:first-child {
    padding-top: 0;
  }
}

.infoMessage {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $label-12-reg;
  padding-top: $space-2;
}

.tipjar {
  position: relative;

  .tipjarEmpty {
    padding: 12px 7px 0px 7px;
    position: relative;
    z-index: 2;
  }

  .tipjarCoin {
    opacity: 0;
    display: block;
    position: absolute;
    z-index: 1;
  }

  .tipjarSplash {
    opacity: 0;
    display: block;
    position: absolute;
    z-index: 1;
  }
}

.tipping {
  .tipjarCoin {
    padding-left: 17px;
    animation-name: tipCoin;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.25, 0.25, 0.075, 1.015);
    animation-fill-mode: forwards;
  }

  .tipjarSplash {
    padding-left: 5px;
    animation-name: tipSplash;
    animation-duration: 1.7s;
    animation-fill-mode: forwards;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .card {
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: $space-3;

    & > div {
      padding-top: 0;
      padding-left: $space-3;
    }

    & > div:first-child {
      padding-top: 0;
      padding-left: 0;
    }
  }

  .infoMessage {
    padding-top: $space-1;
  }
}

@keyframes tipCoin {
  44% {
    opacity: 0;
  }

  45% {
    opacity: 1;
    top: -5px;
  }

  85% {
    opacity: 1;
    top: 24px;
  }

  100% {
    opacity: 1;
    top: 24px;
  }
}

@keyframes tipSplash {
  0% {
    opacity: 0;
  }

  89% {
    opacity: 0;
  }

  90% {
    opacity: 0;
    top: 10px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}
