@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.POAToast {
  @include themify() {
    background: themed('surfacePrimary');
    border: 1px solid themed('borderPrimary');
  }

  box-sizing: border-box;
  border-radius: $space-1;
  display: inline-flex;
  padding: $space-2;
  align-items: flex-start;
  gap: $space-1;
  position: absolute;
  bottom: $space-3;
  right: 50%;
  transform: translate(50%, 0px);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
}

.higherToast {
  bottom: 104px !important;
}

.POAToastInner {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  white-space: nowrap;
}

.POAToastButton {
  background: none;
  border: none;
  padding: 0;
  height: 16px;
  width: 16px;

  & > svg {
    height: 16px;
    width: 16px;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .POAToast {
    bottom: $space-2;
    position: absolute;
    right: 500px;
    transform: none;
  }
}
