@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  position: relative;
}

.desktopButton {
  @include themify() {
    color: themed('contentActive');
  }

  background: none;
  border: none;
  display: none;
  padding: 0;
  text-decoration: underline;
}

.mobileButton {
  @include themify() {
    color: themed('contentActive');
  }

  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
}

.helpContents {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 25px;
  z-index: $zindex-modal-backdrop;
}

.centered {
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
}

.show {
  display: flex;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .desktopButton {
    display: inline;
  }

  .mobileButton {
    display: none;
  }
}
