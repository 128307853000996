@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Menu Preview Banner styles*/
.wrapper {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  border-radius: $border-radius;
  padding: $space-5 $space-3;
  margin: $space-3 0 $space-5;
  position: relative;
  text-align: center;

  &::after {
    @include themify() {
      background-color: themed('borderPrimary');
    }

    bottom: -$space-5;
    content: '';
    height: 1px;
    left: -$space-2;
    position: absolute;
    width: 100vw;
  }
}

.heading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  margin: $space-2 0 $space-1;
}

.subText {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .wrapper::after {
    height: 0;
    margin: 0;
  }
}
