@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Menu Category Nav Styles*/
/* ---------------------------------*/

.categoryNav {
  align-items: center;
  display: flex;
  flex: 1;
  height: $space-7;
  transition: border-top 0.3s;
}

.categoryNavWrap {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
  }

  align-items: center;
  background: $white;
  display: flex;
  position: static;
  z-index: $z-index-nav + 1;

  &.sticky {
    left: 0;
    padding: 0 $space-2;
    position: fixed;
    top: $navbar-height-small;
    width: calc(100% - #{$space-4});
  }
}

.menuCategoryDropdown {
  top: $space-5;

  p {
    font: $label-14-reg !important; /* override component library*/
    letter-spacing: -0.2px !important; /* override component library*/
    text-align: left;
  }
}

.stickyHeaderPadding {
  margin-top: 44px;

  .dropdownWrapper {
    position: fixed;
    top: $navbar-height * 2;
  }
}

.select {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  cursor: pointer;
  display: flex;
  font: $label-16-reg;
  padding-right: $space-3;
  width: calc(100% - #{$space-2});
}

.activeCategory {
  max-width: calc(100vw - 112px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iconCaret {
  margin-left: $space-1;

  &.rotate {
    transform: rotate(180deg);
  }
}

.dropdownOverlay {
  background-color: transparent;
  border: none;
  cursor: auto;
  cursor: initial;
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $z-index-nav + 1;

  &.isActive {
    display: inline;
    display: initial;
  }
}

.searchInput {
  border: none !important;
}

.iconClear {
  height: $space-2;
  padding: $space-2;
  width: $space-2;
}

.iconSearch {
  @include themify() {
    border-color: themed('borderPrimary');
  }

  height: $space-2;
  width: $space-2;

  path {
    @include themify() {
      border-color: themed('contentSecondary');
      fill: themed('contentSecondary');
    }
  }
}

.iconSearchMobile {
  cursor: pointer;
  padding: $space-2;
  padding-right: 0;
}

.menuCategoryNavWrapper {
  flex-grow: 1;
  width: 100%;
}

.mobileSearchInput {
  align-items: center;
  display: flex;
  margin-left: auto;
  transition: width 225ms ease;
  width: 100%;

  &.hide {
    display: block;
    height: 0;
    opacity: 0;
    transition: width 100ms;
    width: 0;
  }
}

.hide {
  display: none;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .menuCategoryNavWrapper {
    flex-grow: initial;
    width: 50%;
  }

  .searchWrapper {
    @include themify() {
      border-left: solid 1px themed('borderPrimary');
    }

    width: 50%;
  }

  .categoryNav {
    height: $space-6;
    position: relative;
  }

  .activeCategory {
    max-width: calc(100% - #{$space-3});
  }

  .select {
    justify-content: space-between;
  }

  .selectOverlay {
    width: 100%;
  }

  .iconSearch {
    @include themify() {
      border-left: solid 1px themed('borderPrimary');
    }
  }
}
