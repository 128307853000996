@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Menu Item Modal Styles*/
/* ---------------------------------*/

.itemModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}

.itemModalContent {
  flex-grow: 1;
  margin-top: -$space-9; /* to accommodate animated header*/
}

.closeIcon {
  background-color: transparent;
  border: none;
  padding: $space-3 $space-2;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $z-index-nav + 3;

  svg {
    height: $icon-close-height;
    width: $icon-close-height;

    path {
      @include themify() {
        fill: themed('contentPrimary');
      }
    }
  }

  &.closeIconPhoto {
    align-items: center;
    background-color: $white;
    border-radius: 100%;
    box-shadow: 0px 0px $space-1 rgba(0, 0, 0, 0.16);
    display: flex;
    height: $space-5;
    justify-content: center;
    margin-left: auto;
    margin-top: -$space-5;
    padding: 0 $space-1;
    position: sticky;
    right: $space-2;
    top: $space-2;
    width: $space-5;

    svg {
      height: $icon-height;
      width: $icon-height;
    }
  }
}

.imageContainer {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100vw;
  justify-content: center;
  overflow: hidden;

  &.hidden {
    height: 0;
    opacity: 0;
    transition: all 0.5s;
  }
}

.modalHeadingWrapper {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
  }

  padding: $space-3 $space-6 $space-3 $space-2;

  &.wide {
    padding: $space-3 $space-2;
  }
}

.itemName {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-20-semi;
}

.description {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  margin-top: $space-1;
}

.modalContentPadding {
  margin: 0 $space-2 $space-3;
}

.modCategoryWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  &:first-child {
    border: none;
  }
}

.modalFooterWrapper {
  bottom: 0;
  position: sticky;
  width: 100%;
}

.instructionsLabel {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  padding: $space-3 0;

  &.noBorder {
    border: none;
  }
}

.textAreaWrapper {
  position: relative;
}

.specialInstructions {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius-square;
  min-height: $space-10;
  padding: $space-2;

  &:-ms-input-placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &::placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }
}

.characterCounter {
  @include themify() {
    color: themed('contentPrimary');
  }

  bottom: $space-1;
  font: $label-12-reg;
  position: absolute;
  right: $space-1;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .itemModal {
    height: auto;
    min-height: auto;
  }

  .closeIcon {
    padding: $space-3;
    right: $space-2;
  }

  .imageContainer {
    height: 400px;
  }

  .modalHeadingWrapper {
    padding: $space-3 $space-9 $space-3 $space-5;

    &.wide {
      padding: $space-3 $space-5;
    }
  }

  .modalContentPadding {
    margin: 0 $space-5 $space-3;
  }
}
