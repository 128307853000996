@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Membership Confirmation Styles*/
/* ---------------------------------*/

.confirmationContent {
  @include themify() {
    color: solid 1px themed('contentPrimary');
  }

  padding: $space-5 0;
  text-align: center;
}

.header {
  font: $headline-32-med;
}

.subheader {
  font: $paragraph-16-reg;
  padding: $space-3 0;
}

.btnWrapper {
  display: flex;
  justify-content: center;
}

/* *//* Small devices and above (tablet, 768px and up) *//**/
@media (min-width: $screen-sm-min) {
  .confirmationContent {
    box-sizing: border-box;
    padding: $space-10 0;
    height: calc(100vh - 142px); /* The height of the nav and footer together*/
  }

  .header {
    font: $headline-48-med;
  }
}
