@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.toggle {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.toggleButton {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  align-items: center;
  border-radius: 25px;
  box-sizing: content-box;
  display: inline-flex;
  height: $space-4;
  justify-content: space-around;
  position: relative;
  transition: 250ms;
  width: $space-7;

  path {
    @include themify() {
      fill: themed('surfaceSecondary');
    }
  }
}

.toggleButton::before {
  @include themify() {
    background-color: themed('surfacePrimary');
  }

  border-radius: 25px;
  box-sizing: border-box;
  content: '';
  height: 28px;
  left: 2px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: inherit;
  width: 28px;
  will-change: transform;
}

.toggle:focus .toggleButton {
  @include themify() {
    outline: themed('borderTertiary') dashed 1px;
  }

  outline-offset: 2px;
}

.toggle[aria-pressed='true'] .toggleButton {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
  }
}

.toggle[disabled] .toggleButton {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  cursor: not-allowed;

  &::before {
    @include themify() {
      background-color: themed('surfaceTertiary');
    }
  }
}

.toggle:hover .toggleButton,
.toggle[aria-pressed='true']:hover .toggleButton {
  @include themify() {
    background-color: themed('surfaceActive');
  }
}

.toggle:focus,
.toggle:focus:not(.focus-visible) .toggleButton {
  outline: 0;
}

.toggle:focus,
.toggle:focus:not(:focus-visible) .toggleButton {
  outline: 0;
}

.toggle[aria-pressed='true'] .toggleButton::before {
  transform: translate(85%, -50%);
}
