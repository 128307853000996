@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.tipLineItem {
  padding-bottom: $space-1;
  display: flex;
}

.tipLineItem:last-child {
  padding-bottom: 0;
  padding-top: $space-1;
}

.tipLabel {
  flex: 1 0 auto;
  position: relative;
}

.tipValue {
  text-align: right;
}
