@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Menu Container Styles*/
/* ---------------------------------*/
.btnBagContainer {
  .btnBag {
    display: flex;
    margin: $space-2;
    width: calc(100% - #{$space-4});
  }
}

.bagBtnLabel {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;
}

.fixedBtn {
  margin-top: -$space-7;

  &.btnBagWrap {
    background-color: $white;
    bottom: 0;
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.16);
    left: 0;
    margin-top: 0;
    padding-bottom: env(safe-area-inset-bottom);
    position: fixed;
    width: 100%;
    z-index: 3; /* to account for category nav overlapping button on smaller devices*/
  }
}

.menu {
  background: $white;
  /* prevents page from jumping to top when search results returns none or few results*/
  min-height: 70vh;
  padding: $space-5 0 $space-2;
  text-align: left;

  &.noFooter {
    min-height: 0;
    padding-bottom: $space-10;
  }
}

.categorySection {
  padding-bottom: $space-3;

  &:last-of-type {
    padding-bottom: 0;
  }

  .menuImpactWrapper {
    @include themify() {
      border-bottom: solid 1px themed('borderPrimary');
    }

    padding: 0 $space-2;
  }
}

.recentlyOrderedWrapper {
  height: 0;
  margin: 0 (-$space-2);
  opacity: 0;
  transition: height 0.3s ease-in, opacity 0.3s ease-in;
}

.recentlyOrderedWrapper.carouselLoaded {
  height: 252px;
  opacity: 1;
}

.categoryNameWrapper {
  padding: 0 0 $space-3;
}

.categoryName {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
  letter-spacing: -$letter-spacing-large;
  position: relative;
}

.categoryNameCarousel {
  padding-left: $space-2;
}

.promoBanner {
  margin-bottom: $space-5;
}

.itemListWrapper {
  min-width: 0;
  width: auto;
  width: initial;
}

.bottomBar {
  position: relative;
}

.menuWrapper {
  display: block !important; /* overrides grid styling for mobile*/
}

.menuWrapper.sticky {
  position: relative;
  z-index: 0;
}

.noResults {
  @include themify() {
    color: themed('contentPrimary');
  }

  text-align: left;

  h4 {
    @include themify() {
      color: themed('contentPrimary');
    }

    font: $label-16-semi;
  }

  .tryAgain {
    font: $paragraph-14-reg;
    margin-top: $space-1;
  }
}

.bagSpace {
  margin-top: 0;

  &.fullBag {
    margin-top: $space-7;
  }
}

.bagIcon {
  margin-right: $space-1;
}

.badgeCheck {
  margin-right: $space-1;
}

.promoApplied {
  align-items: center;
  color: $content-success;
  display: flex;
  font: $label-12-reg;
  margin: $space-2;
}

.poweredByIcon {
  margin-top: $space-5;
  text-align: center;
}

.slideDown {
  animation-duration: 0.2s;
  animation-name: slidedown;
  animation-timing-function: ease-in-out;
}

.slideUp {
  animation-duration: 0.2s;
  animation-name: slideup;
  animation-timing-function: ease-in-out;
}

.promoToast {
  bottom: $space-1;
  opacity: 0;
  position: fixed;
  right: $space-6;
  transform: translateY(24px);
  transition: all 0.2s ease;
  visibility: hidden;
  width: 240px;
  z-index: 2;

  &.withCheckoutButton {
    bottom: $space-12;
  }

  &.isVisible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
}

@keyframes slidedown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes slideup {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .menuWrapper {
    display: grid !important; /* changes to grid styling for sm and up*/
    padding: 0;
    position: relative;
  }

  .menu {
    /* prevents fulfillment picker from shifting up on right column when page size is too small*/
    min-height: 60vh;
    padding: $space-5 0;
  }

  .categoryNameWrapper {
    padding: 0 $space-2 0 0;
  }

  .categoryName {
    padding-bottom: 0;
  }

  .categoryNameCarousel {
    padding-left: 0;
  }

  .categorySection {
    padding-bottom: $space-3;

    &:last-of-type {
      padding-bottom: $space-3;
    }
  }

  .orderTotal {
    position: relative;
  }

  .btnWrapper {
    @include themify() {
      border: solid 1px themed('borderPrimary');
      border-top: none;
    }

    background: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 0 $space-2 $space-3;
  }

  .fixedBag {
    margin: $space-5 0 $space-4;
    position: sticky;
    top: $navbar-height + $space-5;
  }

  .bagErrors {
    margin-top: $space-2;
  }

  .promoApplied {
    margin: $space-2 0 0;
  }

  .bottomBarLeftWrapper {
    @include themify() {
      border-bottom: solid 1px themed('borderPrimary');
    }

    align-items: center;
    background-color: $white;
    display: flex;
    flex-grow: 1;
    padding-top: $space-5;
    position: sticky;
    top: $navbar-height;
    z-index: $z-index-nav;
  }

  .recentlyOrderedWrapper {
    margin: 0;
  }

  .promoToast {
    bottom: $space-3;
    right: $space-7;
  }
}

/* XXLarge devices and above (tablet, 1920px and up) */
@media (min-width: $screen-xxl-min) {
  .itemListWrapper {
    margin: 0;
  }

  .orderTotal {
    padding-right: 0;
  }
}
