@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Promo Code Component Styles*/
/* ---------------------------------*/
.promoWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  margin: 0 $space-3;
  padding: $space-3 0;

  &:hover {
    > svg path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}

.promoDesc {
  @include themify() {
    color: themed('contentSecondary');
  }

  align-items: center;
  display: flex;
  font: $paragraph-14-italic;
}

.icon {
  margin-right: $space-3;
}

.success {
  color: $content-success;
  font: $paragraph-14-reg;
}

.iconEdit {
  background-color: transparent;
  border: none;
  margin-left: $space-3;
  padding: 0;
}
