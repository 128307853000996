@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.alert {
  @include themify() {
    background-color: themed('surfacePrimary');
    color: themed('contentPrimary');
  }

  border-radius: 16px;
  box-shadow: $shadow-soft;
  box-sizing: border-box;
  font: $paragraph-14-reg;
  max-height: calc(100vh - 160px); /* 80px from top and bottom of window*/
  max-width: 460px;
  overflow: scroll;
  padding: $space-7 $space-3 $space-5;
  width: calc(100vw - #{$space-4});

  &.center {
    text-align: center;
  }
}

.iconWrapper {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: $space-2;
  top: $space-2;
}

.iconClose {
  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $grid-breakpoint-m) {
  .alert {
    padding: $space-7 $space-5 $space-5;
  }
}
