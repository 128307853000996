@font-face {
  font-family: 'Matter';
  font-weight: normal;
  src: local('Matter'), url(./fonts/Matter-ChowNow-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Matter';
  font-weight: 500;
  src: local('Matter'), url(./fonts/Matter-ChowNow-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Matter';
  font-weight: 600;
  src: local('Matter'), url(./fonts/Matter-ChowNow-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Matter';
  font-weight: 700;
  src: local('Matter'), url(./fonts/Matter-ChowNow-Bold.woff2) format('woff2');
}
