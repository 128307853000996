@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Polling Modal Styles*/
/* ---------------------------------*/

.progressTracker {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
}

.progressBar {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  border-radius: 2px;
  height: 4px;
  margin-top: $space-3;
  overflow: hidden;
  position: relative;
}

.innerProgressBar {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
  }

  &::before {
    animation: elastic-long 2.1s cubic-bezier(0.75, 0.815, 0.745, 0.345)
      infinite;
    background-color: inherit;
    border-radius: 2px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    will-change: left, right;
  }

  &::after {
    animation: elastic-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.25s;
    background-color: inherit;
    border-radius: 2px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    will-change: left, right;
  }
}

@keyframes elastic-long {
  0% {
    left: -55%;
    right: 100%;
  }

  55% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes elastic-short {
  0% {
    left: -200%;
    right: 100%;
  }

  45% {
    left: 107%;
    right: -12%;
  }

  100% {
    left: 107%;
    right: -12%;
  }
}
