@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.formOverlay {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1; /* put on top of input fields*/

  &.hide {
    display: none;
  }
}

.label span {
  @include themify() {
    color: themed('contentPrimary');
  }
}

.inlineWrap {
  display: flex;
  flex-wrap: wrap;

  div {
    flex: 1 0 calc(50% - #{$space-2});
  }

  div:first-child {
    margin-right: $space-2;
  }

  div:last-child {
    flex-grow: 1;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .inlineFields {
    display: flex;

    > div {
      flex-grow: 1;
      margin-right: $space-2;
    }

    > div:last-child {
      margin-right: 0;
    }

    &.inlineWrap {
      > div {
        margin-right: 0;
      }

      > div:first-child {
        margin-right: $space-2;
      }
    }
  }
}
