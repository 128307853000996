@import '~styles/variables';

/* Promo Modal Component Styles*/
/* ---------------------------------*/

.betterPromo {
  color: $content-success;
  font: $label-12-reg;
  margin-top: $space-1;
}
