@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Fulfillment Details Modal Component Styles*/
/* ---------------------------------*/

.modalContentWrapper {
  &.noClose {
    > button {
      display: none;
    }
  }
}

.modalDetails {
  flex-grow: 1;
  margin: $space-2 0;
}

.whenWrapper {
  margin-bottom: $space-5;
}

.sectionTitle {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  margin-bottom: $space-3;
}
