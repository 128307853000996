@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Delivery Address Component Styles*/
/* ---------------------------------*/
.deliveryAddressWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  margin: 0 $space-3;
  padding: $space-3 0;

  &:hover {
    > svg path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}

.checkoutMobileAddress {
  text-align: left;
}

.deliveryAddress {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;

  p {
    font: $paragraph-14-reg;
  }

  .deliveryInstructions {
    @include themify() {
      color: themed('contenttPrimary');
    }

    font: $paragraph-14-italic;
    margin-top: $space-1;
    text-align: left;

    &.default {
      @include themify() {
        color: themed('contentSecondary');
      }
    }
  }
}

.icon {
  display: inline-block;
  height: $space-3;
  margin-right: $space-3;
  min-width: $space-3;
  width: $space-3;
}

.iconEdit {
  margin-left: $space-3;
  min-width: $icon-small;
  padding: 0;
}
