@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.cardWrapper {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  margin-bottom: $space-1;
}

.cardHeader {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  padding: $space-3;
}

.cardContent {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  padding: $space-3;

  &.contentWithHeader {
    font: $paragraph-14-reg;
  }
}

.cardSubheader {
  font: $paragraph-14-semi;
  padding-bottom: $space-2;

  &.subheaderWithHeader {
    font: $label-20-med;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .cardWrapper {
    margin-bottom: -$space-1;
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .cardWrapper {
    margin-bottom: 0;
  }

  .cardHeader {
    text-align: center;
    font: $label-16-semi;
  }

  .cardSubheader {
    font: $label-16-semi;
  }

  .cardContent {
    box-sizing: border-box;
    font: $paragraph-16-reg;
    height: 240px;
    padding: $space-5;
    text-align: center;
  }
}
