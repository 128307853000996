/* stylelint-disable no-descending-specificity */
@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.checkboxWrapper {
  height: 24px;
  position: relative;
  width: 24px;

  .checkboxVisible {
    @include themify() {
      background-color: themed('surfacePrimary');
      border: solid 1px themed('borderPrimary');
    }

    border-radius: $border-radius-small;
    height: 20px;
    margin: 2px;
    position: absolute;
    width: 20px;
  }

  .iconCheckmark {
    display: none;
    margin-left: 2px;
    margin-top: 2px;
  }

  input {
    cursor: pointer;
    display: block;
    height: 20px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    z-index: 1;

    &:checked + .checkboxVisible {
      @include themify() {
        background-color: themed('surfaceInversePrimary');
        border: solid 1px themed('surfaceInversePrimary');
      }

      background-position: center;

      .iconCheckmark {
        display: block;
      }
    }

    &:hover {
      + .checkboxVisible {
        @include themify() {
          border-color: themed('surfaceActive');
        }
      }

      &:checked {
        + .checkboxVisible {
          @include themify() {
            background-color: themed('surfaceActive');
            border-color: themed('surfaceActive');
          }
        }
      }
    }

    &:focus + .checkboxVisible {
      @include themify() {
        outline-color: themed('borderTertiary');
      }

      outline: 1px dashed;
      outline-offset: 2px;
    }

    &:disabled,
    &:disabled:hover {
      + .checkboxVisible {
        @include themify() {
          background-color: themed('surfaceSecondary');
          border-color: themed('surfaceTertiary');
        }
      }

      &:checked,
      &:checked:hover {
        + .checkboxVisible {
          @include themify() {
            background-color: themed('surfaceTertiary');
            border-color: themed('surfaceTertiary');
          }
        }
      }
    }
  }
}
