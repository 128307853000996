@import '~styles/variables';

/* Menu Category Item Modal Styles*/
/* ---------------------------------*/
.modalFooter {
  background-color: $white;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.16);
  padding: $space-2;
}

.previewNotice {
  margin-bottom: $space-2;
}

.footerControls {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
}

.btnAddItem {
  display: flex;
  flex: 1;
  width: auto;

  > span {
    flex: initial !important; /* override component library*/
  }
}

.label {
  flex: 1 0 auto;
  text-align: left;
}

.price {
  text-align: right;
}

.stepper {
  margin-right: $space-2;
}

.backBtn {
  margin-right: $space-half;
}

.continueBtn {
  margin-left: $space-half;
}

.secondaryButtonContent {
  margin-left: $space-1;

  &::before {
    content: '';
    width: 1px;
    height: $space-3;
    border-right: 1px solid;
    opacity: 0.24;
    margin-right: $space-1;
  }
}
