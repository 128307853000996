@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  @include themify() {
    border-top: 1px solid themed('borderPrimary');
  }

  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: $space-3;
}

.cardNumber {
  font: $label-14-reg;
  margin-left: $space-2;
}
