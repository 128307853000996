$white: #fff;
$black: #000;

// Base Global
$neutral-1: #fff;
$neutral-2: #f2f2f2;
$neutral-3: #dbdbdb;
$neutral-4: #a3a3a3;
$neutral-5: #6e6e6e;
$neutral-6: #1f1f1f;
$neutral-7: #000;

$success-1: #e6f5ee;
$success-2: #21a369;
$success-3: #0e7a4a;

$caution-1: #fae9e6;
$caution-2: #f5563d;
$caution-3: #cc1b00;

$opacity-neutral1-08: rgba(255, 255, 255, 0.08);
$opacity-neutral1-16: rgba(255, 255, 255, 0.16);
$opacity-neutral1-24: rgba(255, 255, 255, 0.24);
$opacity-neutral1-40: rgba(255, 255, 255, 0.4);
$opacity-neutral1-64: rgba(255, 255, 255, 0.64);

$opacity-neutral7-08: rgba(0, 0, 0, 0.08);
$opacity-neutral7-16: rgba(0, 0, 0, 0.16);
$opacity-neutral7-24: rgba(0, 0, 0, 0.24);
$opacity-neutral7-40: rgba(0, 0, 0, 0.4);
$opacity-neutral7-64: rgba(0, 0, 0, 0.64);

// Base Brand - Primary
$paper-1: #f5f4f1;
$paper-2: #dad8d2;
$paper-3: #b2afa4;

$ink-1: #929bad;
$ink-2: #343e52;
$ink-3: #142138;

$berry-1: #fae1e1;
$berry-2: #f98686;
$berry-3: #f94e4e;

$fig-1: #dadff7;
$fig-2: #5268cc;
$fig-3: #223ba8;

// Base Brand - Secondary
$orange-1: #fcd1c8;
$orange-2: #f99885;
$orange-3: #f9603d;

$lemon-1: #f8edcd;
$lemon-2: #f6c774;
$lemon-3: #fcef54;

$basil-1: #afeedf;
$basil-2: #2e9952;
$basil-3: #00bd71;

$icee-1: #dff4f4;
$icee-2: #2738a3;
$icee-3: #50cdf9;

// Base Themed
$vanilla-1: #f5f4f1;
$vanilla-2: #d9d7d3;
$vanilla-3: #2d2a25;
$vanilla-4: #000;

$sage-1: #e6f7ee;
$sage-2: #b9e6d4;
$sage-3: #13aa6d;
$sage-4: #008854;

$eggplant-1: #f7f3ff;
$eggplant-2: #dfd4f5;
$eggplant-3: #8258d4;
$eggplant-4: #6d46bb;

$blueberry-1: #f0f3ff;
$blueberry-2: #cdd7ff;
$blueberry-3: #405de0;
$blueberry-4: #284ce4;

$blueraspberry-1: #e2f5fd;
$blueraspberry-2: #b6e2fa;
$blueraspberry-3: #1c9ceb;
$blueraspberry-4: #1b88d7;

$turquoise-1: #e1fbfb;
$turquoise-2: #abe1e3;
$turquoise-3: #02b3b5;
$turquoise-4: #009292;

$grass-1: #e7f5e3;
$grass-2: #c6e5bc;
$grass-3: #3aac00;
$grass-4: #308f00;

$kiwi-1: #ebf6c0;
$kiwi-2: #ddf197;
$kiwi-3: #9fb800;
$kiwi-4: #8ca200;

$citrus-1: #faf6e0;
$citrus-2: #ecd87f;
$citrus-3: #dfa600;
$citrus-4: #c59300;

$persimmon-1: #faf1e0;
$persimmon-2: #f5d396;
$persimmon-3: #dd8607;
$persimmon-4: #cd6703;

$tomato-1: #fdedeb;
$tomato-2: #f8cec9;
$tomato-3: #da1f07;
$tomato-4: #c11c07;

$raspberry-1: #feedf8;
$raspberry-2: #ffc6ec;
$raspberry-3: #e63ea9;
$raspberry-4: #d0078a;

$grape-1: #fff0fa;
$grape-2: #fac4e9;
$grape-3: #b5388c;
$grape-4: #a22b7b;

$yelp-1: #fdedeb;
$yelp-2: #f8cdc9;
$yelp-3: #cd2e20;
$yelp-4: #b91608;

// Semantic Naming - Surface
$surface-primary: $neutral-1;
$surface-secondary: $neutral-2;
$surface-tertiary: $neutral-3;
$surface-accent-primary: $sage-1; // 1st color in theme stack
$surface-accent-secondary: $sage-2; // 2nd color in theme stack
$surface-active: $sage-4; // 4th color in theme stack
$surface-success: $success-1;
$surface-caution: $caution-1;
$surface-inverse-primary: $sage-3; // 3rd color in theme stack

// Semantic Naming - Content
$content-primary: $neutral-6;
$content-secondary: $neutral-5;
$content-tertiary: $neutral-4;
$content-accent: $success-3;
$content-active: $sage-4; // 4th color in theme stack
$content-chownow: $berry-3;
$content-success: $success-3;
$content-caution: $caution-3;
$content-inverse-primary: $neutral-1;

// Semantic Naming - Borders
$border-primary: $neutral-3;
$border-secondary: $neutral-4;
$border-tertiary: $neutral-6;
$border-caution: $caution-3;
$border-transparent: $opacity-neutral7-24;
$border-inverse-transparent: $opacity-neutral1-24;

// Semantic Naming - Effects
$shadow-strong: $opacity-neutral7-24;
$shadow-soft: $opacity-neutral7-16;
$modal-overlay: $opacity-neutral7-64;
$image-overlay: $opacity-neutral7-40;
