@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.historyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.header {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  padding: $space-5 0;
}

.heading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
  white-space: nowrap;
}

.section {
  margin: $space-5 0 $space-2;
}

.sectionHeading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
}

.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: $space-3;
}

.emptyText {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-reg;
  margin-top: $space-3;
}

.fixedSpinner {
  > div {
    position: fixed !important;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .section {
    margin: $space-7 0 0;
  }

  .empty {
    margin-top: $space-7;
  }

  .emptyText {
    font: $label-20-reg;
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .heading {
    font: $headline-40-med;
  }

  .header {
    padding: $space-7 0;
  }
}
