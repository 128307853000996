@import 'mixins';
@import 'functions';
@import 'variables';

// Type Styles
// ---------------------------------
body {
  @include themify() {
    color: themed('contentPrimary');
  }

  color: $content-primary;
  font: $paragraph-14-reg;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5 {
  @include themify() {
    color: themed('contentPrimary');
  }

  color: $content-primary;
  margin: 0;
}

label {
  @include themify() {
    color: themed('contentPrimary');
  }

  color: $content-primary;
}

h1 {
  font: $headline-32-med;
}

h2 {
  font: $headline-24-med;
}

h3 {
  font: $label-16-semi;
}

h4 {
  font: $label-16-semi;
}

p {
  font: $paragraph-14-reg;
  margin: 0;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  h2 {
    font: $headline-40-med;
  }

  h3 {
    font: $headline-24-med;
  }

  p {
    font: $paragraph-16-reg;
  }
}
