@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Hours Modal Styles*/
/* ---------------------------------*/
.modalHeader {
  margin-bottom: 0;
}

.tabs {
  margin-bottom: $space-5;
}

.closureCard {
  background-color: $surface-caution;
  border-radius: $border-radius;
  color: $content-caution;
  font: $paragraph-14-reg;
  margin-bottom: $space-5;
  padding: $space-3 $space-2 $space-2;
}

.closureHeading {
  font: $label-14-semi;
  margin-bottom: $space-1;
}

.closure {
  margin-bottom: $space-1;
}

.fulfillmentUnavailable {
  @include themify() {
    background-color: themed('surfaceSecondary');
    color: themed('contentSecondary');
  }

  border-radius: $border-radius;
  font: $label-14-reg;
  margin-top: $space-3;
  padding: $space-3 $space-2;
}
