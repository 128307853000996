/* stylelint-disable value-list-comma-newline-after */
@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Mod Category Component Styles*/
/* ---------------------------------*/
.modHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $space-3 0;
}

.modList {
  padding-bottom: $space-3;
}

.modName {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  margin-right: $space-2;
}

.hasErrorIcon {
  height: 15px;
  width: 15px;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .modHeader {
    margin-top: 0;
  }
}
