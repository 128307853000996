@import '~styles/colors';
@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.savingsWrapper {
  border-top: solid 1px $white;
  margin-top: $space-3;
}

.savingsSection {
  @include themify() {
    color: themed('contentInversePrimary');
  }

  align-items: center;
  display: flex;
  padding: $space-3 0;

  &.borderBottom {
    @include themify() {
      border-bottom: solid 1px themed('borderInverseTransparent');
    }
  }

  &.borderTop {
    @include themify() {
      border-top: solid 1px themed('borderInverseTransparent');
    }
  }
}

.amount {
  font: $headline-24-med;
  margin-right: $space-1;
  min-width: 96px;
}

.description {
  font: $paragraph-14-reg;
}
