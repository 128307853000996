@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Locate Me Dropdown Component Styles*/
/* ---------------------------------*/

.error {
  color: $content-caution;
  font: $label-14-reg;
  padding: 0 $space-2;
  text-align: left;
}

.locateMeItem p {
  font: $label-14-reg !important; /* override component library*/
}
