/* stylelint-disable no-descending-specificity */
@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.tabs {
  @include themify() {
    color: themed('contentPrimary');
  }

  position: relative;

  hr {
    @include themify() {
      border-color: themed('borderPrimary');
    }

    border-style: solid;
    border-width: 0 0 1px 0;
    margin: 0 0 $space-1 0;
  }
}

.labels {
  @include themify() {
    color: themed('contentSecondary');
  }

  display: flex;
  text-align: center;

  span {
    cursor: pointer;
    font: $label-16-med;
    font-feature-settings: none;
    font-variant-ligatures: none;
    margin-right: $space-5;
    padding: 14px 0;

    &:last-of-type {
      margin-right: 0;
    }
  }

  span:hover {
    @include themify() {
      color: themed('contentPrimary');
    }
  }
}

span.selected {
  @include themify() {
    color: themed('contentPrimary');
  }

  margin-bottom: 2px;
}

.selectionMarker {
  @include themify() {
    background-color: themed('borderTertiary');
  }

  height: 2px;
  width: 0px;
  position: absolute;
  top: 48px;
  left: 0;
  transition: left 0.3s, width 0.3s;
}

.fill {
  justify-content: space-between;

  span {
    flex: 1;
  }
}

.small {
  .labels {
    span {
      font: $label-14-med;
      margin-right: $space-3;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .selectionMarker {
    top: 44px;
  }
}
