@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Item Expired Modal Component Styles*/
/* ---------------------------------*/

.message {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  margin-bottom: $space-3;
}

.itemWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  display: flex;
  padding: $space-2 0;
}

.quantity {
  color: $content-caution;
  font: $label-14-semi;
  letter-spacing: $letter-spacing-large;
  margin-right: $space-1;
  width: 20px;
}

.itemName {
  color: $content-caution;
  font: $label-14-semi;
  letter-spacing: $letter-spacing-large;
}

.itemModifier {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  margin-top: $space-1;
}
