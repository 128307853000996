@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Post Purchase Impact Modal Component Styles*/
/* ---------------------------------*/

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalCopy {
  align-items: center;
  color: $neutral-1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 248px;
  text-align: center;
}

.modalHeadline {
  font: $headline-24-med;
  opacity: 0;
}

.newUserAnimation {
  animation: newUserFadeInOut 2900ms ease-out 600ms 1 forwards;
}

.returningUserAnimation {
  animation: fadeInOut 2100ms ease-out 600ms 1 forwards;
}

.contentTwo {
  align-items: center;
  animation: fadeInUp 300ms ease-out 0ms forwards;
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  @include themify() {
    color: themed('contentInversePrimary');
  }

  animation: fadeIn 300ms ease-out 600ms forwards;
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  font: $label-14-reg;
  flex-direction: column;
  margin: $space-5 auto 0;
  text-align: center;
  opacity: 0;

  &:focus > div {
    outline: dashed $white 1px;
  }
}

/* Animations */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY($space-2);
  }

  14% {
    opacity: 1;
    transform: translateY(0);
  }

  86% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes newUserFadeInOut {
  0% {
    opacity: 0;
    transform: translateY($space-2);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY($space-2);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
