@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.modalWrapper {
  background-color: $white;
  box-sizing: border-box;
  left: 50%;
  overflow-y: auto;
  position: fixed;
  top: 20%;
  transform: translate(-50%, -20%);
  width: 100%;
  z-index: $zindex-modal;

  &.backgroundDark {
    @include themify() {
      background-color: themed('surfaceInversePrimary');
    }
  }
}

.modal {
  height: 100%;

  &.alert {
    height: auto;

    .modalWrapper {
      border-radius: $border-radius;
      width: auto;
    }
  }
}

.overlay {
  animation: fadeIn 0.3s forwards;
  background-color: rgba(0, 0, 0, 0.64);
  height: 100vh;
  left: 50%;
  opacity: 0;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  will-change: opacity;
  z-index: $zindex-modal-backdrop;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .modal {
    height: auto;
  }

  .modalWrapper {
    border-radius: $border-radius;
    max-height: calc(100vh - 160px);
    top: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
  }
}

@keyframes fadeIn {
  100% {
    opacity: 1;
  }
}
