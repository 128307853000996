@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* AddressSelector Component Styles*/
/* ---------------------------------*/

.addressList p {
  font: $label-14-reg !important; /* override component library*/
}

.addressList button {
  text-align: left;
}
