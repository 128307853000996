/* We don't do this anywhere else, but for Empty menu state we try to mock the menu styles*/
/* behind a heavy blur, so we're just referencing all the relevant menu styles here*/
.categorySection {
  composes: categorySection from '~containers/Menu/styles.module.scss';
}

.categoryName {
  composes: categoryName from '~containers/Menu/styles.module.scss';
}

.menu {
  composes: menu from '~containers/Menu/styles.module.scss';
}

.itemsList {
  composes: itemsList from '../MenuItems/styles.module.scss';
}

.itemContainer {
  composes: itemContainer from '../MenuItem/styles.module.scss';
}

.name {
  composes: name from '../MenuItem/styles.module.scss';
}

.price {
  composes: price from '../MenuItem/styles.module.scss';
}
