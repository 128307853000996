@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

$progress-bar-height: 4px;

.bar {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  border-radius: calc($progress-bar-height / 2);
  height: $progress-bar-height;
  margin: $space-2 0;
  position: relative;
}

.innerBar {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
  }

  border-radius: calc($progress-bar-height / 2);
  display: block;
  height: $progress-bar-height;
  width: 3%;
}

@media (min-width: $screen-md-min) {
  $progress-bar-height: 4px;

  .bar {
    border-radius: calc($progress-bar-height / 2);
    height: $progress-bar-height;
  }

  .innerBar {
    border-radius: calc($progress-bar-height / 2);
    height: $progress-bar-height;
  }
}
