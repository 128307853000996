@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Time Unavailable Modal Component Styles*/
/* ---------------------------------*/

.description {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  margin-bottom: $space-3;
}
