/* stylelint-disable value-list-comma-newline-after */
@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.tag {
  @include themify() {
    border: 1px solid themed('borderPrimary');
    color: themed('contentPrimary');
  }

  align-items: center;
  background-color: transparent;
  border-radius: $border-radius-small;
  box-sizing: border-box;
  display: flex;
  font: $caption-10-med;
  height: 24px;
  letter-spacing: 0.8px;
  padding: $space-half $space-1;
  text-indent: 0.8px;
  text-transform: uppercase;
  text-wrap: nowrap;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    transform 0.2s;
  width: -moz-fit-content;
  width: fit-content;
}

.icon {
  height: $icon-height;
  padding-right: $space-half;
}

.caution {
  @include themify() {
    border-color: $content-caution;
    color: $content-caution;
  }

  .icon path {
    fill: $content-caution;
  }
}
