@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.title {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  margin-bottom: $space-3;
}

.rewardContainer {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  margin-bottom: $space-3;
  padding: $space-3 $space-3;
}

.rewardHeader {
  display: flex;
  justify-content: space-between;
}

.restaurantInfo {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: inline-block;
  font: $label-14-reg;
  letter-spacing: $letter-spacing-large;
}

.restaurantName {
  font: $label-14-semi;
}

.progressCardsWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  margin-top: $space-3;
}

.progressInfo {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;
  font: $paragraph-14-reg;
  padding: calc($space-3 - $space-half) 0;

  > div {
    margin-right: $space-1;
  }
}

.progressInfo:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.optOut {
  font: $label-14-reg !important; /* to override component library*/
  height: min-content;
  white-space: nowrap;
  padding-left: $space-2;
}
