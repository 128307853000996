@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.appBannerWrapper {
  @include themify() {
    background-color: themed('surfacePrimary');
    border-bottom: solid 1px themed('borderPrimary');
  }

  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: $space-9;
  padding: 0 $space-1;
  width: 100%;
}

.appBannerContentWrapper {
  width: 100%;
}

.appBannerContent {
  align-items: center;
  display: flex;
  font: $paragraph-14-semi;
  justify-content: space-between;
  text-decoration: none;
}

.iconClose {
  height: $space-1;
  padding: 0 $space-2 0 $space-1;
}

.appName {
  font: $paragraph-14-semi;
  padding-top: $space-half;
}

.iconApp {
  height: $space-4;
}
