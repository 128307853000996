@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Dropdown Menu Component Styles*/
/* ---------------------------------*/

.authMenu {
  position: relative;
}

.listLink {
  display: block;
  height: 100%;
  letter-spacing: 0;
  padding: $space-2;
  text-decoration: none;
  transition: color 0.2s;
  width: 100%;

  &:visited,
  &:link {
    @include themify() {
      color: themed('contentPrimary');
    }
  }
}

.listItem {
  @include themify() {
    color: themed('contentPrimary');
  }

  cursor: pointer;

  &:first-child {
    &::after {
      border-top-left-radius: $border-radius-square;
      border-top-right-radius: $border-radius-square;
    }
  }

  &:last-child {
    &::after {
      border-bottom-left-radius: $border-radius-square;
      border-bottom-right-radius: $border-radius-square;
    }
  }

  &:hover {
    @include themify() {
      background-color: themed('surfaceSecondary');
    }
  }

  &:focus {
    @include themify() {
      background-color: themed('surfaceSecondary');
    }

    outline: none;
    position: relative;

    .listLink {
      position: relative;
      z-index: 1;
    }

    &::after {
      @include themify() {
        border: 1px themed('borderTertiary') dashed;
      }

      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &:active {
    background-color: transparent;
    color: $sage-4;

    .listLink {
      @include themify() {
        color: themed('surfaceActive');
      }
    }

    &::after {
      border: none;
    }
  }

  &.restaurantLink {
    @include themify() {
      border-top: 1px solid themed('borderPrimary');
    }
  }
}

.dropdownOverlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s;
  will-change: opacity;
  z-index: $z-index-nav;

  &.visible {
    pointer-events: initial;
  }
}

.btnAuth {
  left: $space-1;
  position: absolute;
}

.iconProfile {
  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.dropdownToggle {
  align-items: center;
  display: flex;
}

.heading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .dropdownMenu {
    @include themify() {
      color: themed('contentPrimary');
    }

    background-color: $white;
    border-radius: $border-radius-square;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    font-weight: $font-weight-regular;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: left;
    width: 160px;
    z-index: $z-index-nav + 1;

    &.withMembership {
      /* Used when we show the membership banner*/
      width: 300px;
    }

    &.isMarketplace {
      width: 246px;

      .listLink {
        font-weight: $font-weight-medium;
      }
    }
  }

  .name {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .iconProfile {
    height: $icon-height;
    margin-top: -2px;
    padding-right: $space-1;
    width: $icon-height;
  }

  .btnAuth {
    @include themify() {
      color: themed('contentPrimary');
    }

    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: $space-5;
    left: 0;
    margin: 0;
    padding: $space-1 0 $space-1;
    position: relative;
    text-decoration: none;
    white-space: nowrap;

    &.authOpen {
      .heading {
        @include themify() {
          color: themed('surfaceActive');
        }
      }

      .iconProfile {
        path {
          @include themify() {
            fill: themed('surfaceActive');
          }
        }
      }
    }
  }
}

/* XLarge devices and above (1440px and up) */
@media (min-width: $app-container-width) {
  .heading {
    font: $label-16-reg;
  }
}
