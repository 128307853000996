@import '~styles/variables';

/* Ordering Unavailable Modal Component Styles*/
/* ---------------------------------*/

.displayTime {
  align-items: center;
  display: flex;
  font: $label-14-semi;
  justify-content: center;
  letter-spacing: $letter-spacing-large;
}

.iconDate {
  margin-right: $space-1;
}

.description {
  font: $paragraph-14-reg;
  margin-top: $space-3;
}

.reverse {
  display: flex;
  flex-direction: column-reverse;

  .displayTime {
    margin-top: $space-3;
  }

  .description {
    margin-top: 0;
  }
}
