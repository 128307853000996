@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.optoutBtn {
  margin-bottom: $space-2;
}

.content {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
}

.restaurantName {
  font: $paragraph-14-semi;
}
