@use 'sass:map';

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'themes.scss';

@mixin underline {
  display: inline-block;
  position: relative;

  &::after {
    @include themify() {
      background-color: themed('surfaceInversePrimary');
    }

    background: $surface-inverse-primary;
    bottom: -2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  transform: $transforms;
}

@include keyframes(rotation) {
  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(360deg));
  }
}

// Using Flex instead of Grid for IE11 compatibility
// This calculates "grid-column-gap"
@mixin columnGap($num-columns: 2, $gutter: 8px) {
  width: calc(
    1 / #{$num-columns} * 100% - (1 - 1 / #{$num-columns}) * #{$gutter}
  );
}

/*
  Used for wrapping theme specific styles.
  Needs to be used with the themed() function in order to access
  theme specific variables.

  Outputs theme specific styles for each defined theme.

  For example, if we have two themes defined in our themes.scss file,
  we can write one declaration in our component stylesheet like so:
    .btn {
      @include themify() {
        background-color: themed('buttonColor');
      }
    }

  Which will output:
    .theme-marketplace .btn {
      background-color: #5eb3b7;
    }
    .theme-direct .btn {
      background-color: #13aa6d;
    }
*/
@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    :global(.theme-#{$theme}) & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map.get(map.get($themes, $theme), '#{$key}');
        $theme-map: map.merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}
