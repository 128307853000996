@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.tagNotice {
  display: flex;
}

.small {
  font: $label-12-reg;
}

.large {
  font: $label-14-reg;
}

.icon {
  margin-right: $space-1;
  height: $icon-small;
  width: $icon-small;
}

.neutral {
  @include themify() {
    color: themed('contentPrimary');
  }
}

.success {
  @include themify() {
    color: $content-success;
  }
}

.caution {
  @include themify() {
    color: $content-caution;
  }
}
