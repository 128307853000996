@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.total {
  color: $black; /* override for social pay, should not be mapped to our color scheme*/
  display: block;
  font: $paragraph-14-semi;
  margin-bottom: $space-1;
  text-align: center;
}

.socialPayButton {
  width: 100%;

  button {
    width: 100%;
  }

  &.applePay {
    border-radius: $border-radius-round;
    align-items: center;
    background-color: $black;
    border: none;
    height: $space-6;
    justify-content: center;
  }

  &.hide {
    height: 0;
    visibility: hidden;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btnSubmit {
  text-transform: capitalize;

  > span {
    flex: initial !important;
  }
}

.secondaryButtonContent {
  margin-left: $space-1;

  &::before {
    content: '';
    width: 1px;
    height: $space-3;
    border-right: 1px solid;
    opacity: 0.24;
    margin-right: $space-1;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .total {
    display: none;
  }
}
