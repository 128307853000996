@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Checkout Container Styles*/
/* ---------------------------------*/

/* all !importants due to component library specificity issue*/
/* should be able to clean up following CN-23127*/
.checkout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* minHeight only applies to Order Direct or Marketplace */
  &.withMinHeight {
    min-height: 100vh;
  }
}

.diningSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.checkoutWrapper {
  display: block !important;
  margin: 0 !important;
}

.checkoutDetails {
  margin: 0 $space-2 $space-5;
}

.header {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
  letter-spacing: $letter-spacing-headline-24;
  margin-top: $space-5;
}

.section {
  margin-top: $space-5;

  &.extraMargin {
    margin-bottom: 120px;
  }
}

.orderRight {
  display: none;
}

.btnWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  background-color: $white;
  bottom: 0;
  margin-top: $space-5;
  padding: $space-2;
  position: fixed;
  width: calc(100% - #{$space-4});
  z-index: 3; /* Need to bump for tip jar animation*/
}

.errorsInline {
  margin-bottom: $space-2;
}

.poweredBy {
  margin-top: $space-5;
  text-align: center;
}

.fixedSpinner {
  > div {
    position: fixed !important;
  }
}

.footer {
  margin-bottom: $space-10;

  &.tall {
    margin-bottom: 108px;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .checkoutWrapper {
    display: grid !important;
    margin: 0 $space-4 !important;
  }

  .checkoutDetails {
    margin: 0;
  }

  .orderInline {
    display: none;
  }

  .orderRight {
    display: block;
    margin-bottom: $space-5;
  }

  .errorsRight {
    display: flex;
    justify-content: center;
    margin-top: $space-2;
  }

  .orderSummaryRight {
    margin-top: $space-5;
  }

  .btnWrapper {
    @include themify() {
      border-top: none;
    }

    display: flex;
    flex-direction: column-reverse;
    margin-bottom: $space-5;
    padding: 0;
    position: static;
    position: initial;
    width: 100%;
  }

  .errorsInline {
    display: flex;
    justify-content: center;
    margin: $space-2 0 0;
  }

  .section.extraMargin {
    margin-bottom: 0;
  }

  .footer,
  .footer.tall {
    margin: 0;
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .checkoutWrapper {
    margin: 0 $space-7 !important;
  }

  .header {
    font: $headline-40-med;
    letter-spacing: $letter-spacing-headline-40;
  }

  .orderRight {
    grid-column-start: 9;
  }
}

/* XLarge devices and above (1440px and up) */
@media (min-width: $screen-xl-min) {
  .checkoutWrapper {
    margin: 0 auto !important;
  }
}
