@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  border-radius: $border-radius;
  padding: $space-3;
  margin-bottom: $space-3;
}

.header {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  letter-spacing: $letter-spacing-large;
  margin-bottom: $space-1;
}

.body {
  @include themify() {
    color: themed('contentPrimary');
  }
}

.outlink {
  @include themify() {
    color: themed('contentPrimary');

    &:hover {
      color: themed('contentActive');
      -webkit-text-decoration: underline themed('contentActive');
              text-decoration: underline themed('contentActive');
    }
  }

  margin-top: $space-1;
}
