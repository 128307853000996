@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.menuSimpleBtn {
  @include themify() {
    background-color: themed('surfacePrimary');
    color: themed('contentPrimary');
  }

  align-items: flex-start;
  border: 1px dashed transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $space-2;
  width: 100%;

  p {
    letter-spacing: 0.2px;
  }

  svg {
    path {
      @include themify() {
        fill: themed('content-primary');
      }
    }
  }

  &.selected {
    @include themify() {
      background-color: themed('surfaceAccentPrimary');
    }
  }

  &.focus-visible {
    @include themify() {
      background-color: themed('surfaceSecondary');
      border: 1px dashed themed('borderTertiary');
    }

    outline: none;
  }

  &:focus-visible {
    @include themify() {
      background-color: themed('surfaceSecondary');
      border: 1px dashed themed('borderTertiary');
    }

    outline: none;
  }

  &:active {
    @include themify() {
      background-color: themed('surfaceAccentPrimary');
    }
  }
}

.contentWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  > svg {
    height: $space-2;
    margin-right: $space-1;
    width: $space-2;
  }
}

.header {
  font: $label-14-semi;
  margin: 0;
}

@media (min-width: $grid-breakpoint-m) {
  .menuSimpleBtn {
    &:hover {
      @include themify() {
        background-color: themed('surfaceSecondary');
      }
    }
  }
}
