@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.detailsModule {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  padding: $space-3;
}

.tagWrapper {
  margin-bottom: $space-2;
}

.sticker {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
  }

  align-items: center;
  border-radius: 24px;
  display: flex;
  height: $space-6;
  justify-content: center;
  margin-bottom: $space-3;
  width: $space-6;
}

.restaurantName {
  @include themify() {
    color: themed('contentPrimary');

    &:active,
    &:hover {
      color: themed('contentActive');
    }
  }

  font: $label-16-semi;
  text-decoration: none;
}

.header {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
}

.bodyText {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  padding-top: $space-1;

  &.bold {
    font: $paragraph-14-semi;
    padding: 0;
  }
}

.help {
  margin-top: $space-2;
}

.subModule {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  display: flex;
  margin-top: $space-3;
  padding-top: $space-3;
}

.centered {
  align-items: center;
}

.iconHeart {
  margin-right: $space-1;
}

.iconBadge {
  height: $space-2;
  margin-right: $space-1;
}

.savingsLink {
  @include themify() {
    color: themed('contentPrimary');
  }

  background-color: transparent;
  border: none;
  margin-left: $space-half;
  padding: 0;
  text-decoration: underline;
}

.memberText {
  line-height: 20px;
}
