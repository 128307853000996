@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.invalidCardWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  margin: 0 $space-3;
  padding: $space-3 0;

  &.noPadding {
    padding-bottom: 0;
  }
}

.invalidCardSectionLabel {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: flex;
}

.iconLock {
  margin-right: $space-1;

  g path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.inputWrapper {
  display: flex;
  margin-top: $space-2;
}

.cvvInput,
.expiredInput {
  width: 156px;

  > div {
    margin-bottom: 0;
  }
}

.expiredInput {
  margin-right: $space-2;
}
