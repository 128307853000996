@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.geosuggest {
  position: relative;
  text-align: left;
}

input[type='text'].geosuggest__input {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-16-reg;
  padding: 0 $space-5;
  text-overflow: ellipsis;

  &:-ms-input-placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }

    font: $paragraph-16-reg;
  }

  &::placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }

    font: $paragraph-16-reg;
  }

  &.error {
    border-color: $content-caution;
  }
}

.geosuggest__suggests {
  @include themify() {
    background: themed('contentInversePrimary');
    border: themed('borderPrimary');
  }

  border-radius: $border-radius-square;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  left: 0;
  list-style: none;
  margin-top: $space-half;
  max-height: 25em;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: $z-index-nav;

  &:not(.geosuggestHidden) {
    &::after {
      background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
      background-position: right;
      background-repeat: no-repeat;
      background-size: 120px 14px;
      content: '';
      display: block;
      margin: 0 $space-1 $space-1;
      padding: 1px 1px 1px 0;
      text-align: right;
    }
  }
}

.geosuggestHidden {
  border-width: 0;
  max-height: 0;
  overflow: hidden;
}

.geosuggest__item {
  @include themify() {
    color: themed('contentPrimary');
  }

  cursor: pointer;
  font: $label-14-reg;
  padding: $space-2;
  text-align: left;
  transition: background-color 0.2s;

  &:hover {
    @include themify() {
      color: themed('contentSecondary');
    }
  }
}

.geosuggest__item:hover,
.geosuggest__item:focus,
.geosuggestActive,
.geosuggestActive:hover,
.geosuggestActive:focus {
  background-color: rgba($content-inverse-primary, 0.4);
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .geosuggest__suggests:not(.geosuggestHidden)::after {
    background-image: none;
    margin: 0;
  }
}
