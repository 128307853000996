@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Restaurant Info Block Container Styles*/
/* ---------------------------------*/
.restaurantDetails {
  @include themify() {
    color: themed('contentPrimary');
  }

  background: $white;
  font: $label-14-reg;
}

.map {
  margin-left: -$space-2;
  width: calc(100% + #{$space-4});
}

.restaurantInfo {
  padding: $space-5 0;
}

.name {
  font: $label-16-semi;
  margin-bottom: $space-1;
}

.address {
  margin-bottom: $space-1;
}

.phoneLink {
  display: block;
  margin-bottom: $space-2;
  text-decoration: underline;
}

@media (min-width: $screen-sm-min) {
  .map {
    margin-left: -$space-5;
    width: calc(100% + #{$space-10});
  }
}
