@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Confirm Account Modal Component Styles*/
/* ---------------------------------*/

.inputGroup {
  display: flex;
  justify-content: space-between;

  > div {
    width: calc(50% - #{$space-1});
  }
}

.input {
  margin-top: $space-3;

  label {
    @include themify() {
      color: themed('contentPrimary');
    }

    display: flex;
    font: $label-14-reg;
    margin-bottom: $space-1;
  }
}

.button {
  margin-top: $space-3;
}

.error {
  color: $content-caution;
  display: block;
  font: $label-12-reg;
  margin-top: $space-1;
  text-align: left;
}

.poweredBy {
  display: block;
  margin-top: $space-5;
}
