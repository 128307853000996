@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.modifierTitle {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  padding: $space-3 0;
}

.modCategoryWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }
}
