@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.currencyInput {
  /* need to override _forms.scss file as theming does not work on global styling*/
  @include themify() {
    border: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  &:-ms-input-placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &::placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &:disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }
  }

  &:hover {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }

  &:focus {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }
}
