@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.modalHeadline {
  @include themify() {
    border-bottom: solid 1px themed('borderInverseTransparent');
  }

  font: $headline-24-med;
  margin-bottom: $space-3;
  padding-bottom: $space-3;
}

.modalSubcontent {
  font: $paragraph-14-reg;
}
