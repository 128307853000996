@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Tip Calculator Component Styles*/
/* ---------------------------------*/

.tipWrapper {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tipHeader {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  padding: $space-3;
}

.tipDetailsWrapper {
  @include themify() {
    color: themed('contentPrimary');
  }

  padding: $space-3;
}

.subheading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  margin-bottom: $space-2;
}

.radioButton label {
  padding: $space-2 0 !important; /* override component library*/
}
