@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Hours List Component Styles*/
/* ---------------------------------*/

.hours {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  margin-top: $space-2;
}

.dayItem {
  display: flex;
  font: $label-14-reg;
  padding: $space-1 0;
}

.day {
  flex: 1;
}

.hoursRange {
  margin: 0;
  text-align: right;
}

.active {
  font: $label-14-semi;
}
