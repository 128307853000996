@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.stepperCount {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  align-items: center;
  border-radius: 24px;
  box-sizing: border-box;
  display: inline-flex;
  padding: $space-2;
}

.stepperIcon {
  background: transparent;
  border: none;
  cursor: pointer;
  height: $space-2;
  padding: 0;
  width: $space-2;

  &.disabled {
    cursor: auto;
    cursor: initial;

    svg {
      display: none;
    }
  }

  &:hover svg path {
    @include themify() {
      fill: themed('contentActive');
    }
  }

  &:focus {
    outline: 1px dashed;
    outline-offset: 2px;
  }
}

.count {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-med;
  min-width: 20px;
  padding: 0 $space-2;
  text-align: center;
}
