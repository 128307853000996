@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Tip Adjustment Modal Component Styles*/
/* ---------------------------------*/

.modalContent {
  height: 100%;
}

.customTip {
  margin-top: $space-3;
}

.errorInput {
  border-color: $border-caution;

  &:hover,
  &:focus {
    border-color: $border-caution !important;
  }
}

.errorMessage {
  color: $content-caution;
  font: $label-12-reg;
  padding-top: $space-1;
}

.tipComparisonModule {
  @include themify() {
    background: themed('surfaceSecondary');
  }

  border-radius: $border-radius;
  margin-top: $space-5;
  padding: $space-3;

  .tipComparison {
    @include themify() {
      color: themed('contentPrimary');
    }

    display: flex;
    justify-content: space-between;
  }

  .currentTip {
    padding-bottom: $space-2;

    > p {
      @include themify() {
        color: themed('contentPrimary');
      }

      font: $label-14-reg;
    }
  }

  .updatedTip {
    > p {
      @include themify() {
        color: themed('contentAccent');
      }

      font: $label-14-semi;
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .modalContent {
    min-height: 180px;
  }
}
