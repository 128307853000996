@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Membership Banner Component Styles*/
/* ---------------------------------*/

.icon {
  margin-bottom: $space-2;
}

.heading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  text-align: center;
}

.btnBrowse {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
    color: themed('contentInversePrimary');
  }

  align-items: center;
  border-radius: 20px;
  display: flex;
  font: $label-14-med;
  height: $space-5;
  justify-content: center;
  margin-top: $space-2;
  padding: 0 $space-2;
  text-decoration: none;

  &:hover {
    @include themify() {
      background-color: themed('surfaceActive');
    }
  }

  &:focus {
    @include themify() {
      outline-color: themed('borderTertiary');
    }

    outline: 1px dashed;
    outline-offset: 2px;
  }
}

.renew {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-12-reg;
  margin-top: $space-2;
}

.banner {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  align-items: center;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  margin-top: $space-3;
  padding: $space-5 $space-3;

  &.minimized {
    padding: $space-3;
    flex-direction: row;

    .icon {
      margin: 0 $space-2 0 0;
    }

    .heading {
      flex-grow: 1;
      text-align: left;
    }

    .btnBrowse {
      display: none;
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .banner {
    margin-top: $space-5;
  }
}
