@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Locations Container Styles*/
/* ---------------------------------*/
.locations {
  height: calc(100vh - #{$navbar-height});
}

.locationsList {
  max-height: calc(100vh - #{$navbar-height});
  padding: $space-3 $space-2;
  position: relative;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .locationsList {
    @include themify() {
      border-left: solid 1px themed('borderPrimary');
    }

    padding: $space-3;
    overflow-y: scroll;
  }
}
