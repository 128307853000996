@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.singleUseWrapper {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  align-items: center;
  background: none;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $space-5;
  padding: $space-3;
}

.icon {
  margin-right: $space-3;
  min-width: $space-3;
}

.singleUse {
  align-items: center;
  display: flex;
  margin-right: $space-3;
}
