@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.thumbnailContainer {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  border-radius: $border-radius;
  display: flex;
  height: 100%;
  width: 100%;

  &.hidden {
    height: 0;
    opacity: 0;
    transition: all 0.05s;
  }
}

.image {
  height: 100%;
  object-fit: cover;
  width: 100%;

  &.rounded {
    border-radius: $border-radius;
  }
}

.thumbnailContainerSmall {
  border-radius: $border-radius-square;
  height: $thumbnail-width-carousel;
  min-height: $thumbnail-width-carousel;
  min-width: $thumbnail-width-carousel;
  width: $thumbnail-width-carousel;

  .image.rounded {
    border-radius: $border-radius-square;
  }
}
