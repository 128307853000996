@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.orderDetailsWrapper {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.orderDetailsHeader {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  letter-spacing: $letter-spacing-large;
  padding: $space-3;
}

.restaurantDetailsWrapper {
  align-items: center;
  display: flex;
  padding: $space-3;
}

.icon {
  align-self: center;
  margin-right: $space-3;
}

.restaurantDetails {
  p {
    @include themify() {
      color: themed('contentPrimary');
    }

    font: $paragraph-14-reg;
  }
}

.outlink {
  display: inline-block;
  margin-top: $space-1;
  text-decoration: underline !important; /* override component library*/
}
