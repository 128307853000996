@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Promo Banner Styles*/
.promoBannerWrapper {
  overflow: hidden;
}

.promoCarouselWrapper {
  display: flex;

  &.scrollable {
    overflow-x: auto;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
}

.bannerWrapper {
  margin-right: $space-2;
}

.promoBanner {
  align-items: center;
  background-color: $surface-success;
  border-radius: $border-radius;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 100%;
  padding: $space-3 $space-2;
  position: relative;
  scroll-snap-align: center;
  width: calc(100vw - #{$space-4});

  &.small {
    justify-content: initial;
    padding: $space-3 $space-2;
  }
}

.promoDescriptionWrapper {
  margin-left: $space-2;
}

.sticker {
  min-height: $space-5;
  min-width: $space-5;
}

.promoDescription {
  -webkit-box-orient: vertical;
  color: $content-success;
  display: -webkit-box;
  font: $label-14-med;
  -webkit-line-clamp: 2;
  line-height: 20px;
  overflow: hidden;

  &.noTruncation {
    /* no truncation on checkout*/
    display: block;
    overflow: visible;
    overflow: initial;
    white-space: normal;
    white-space: initial;
  }

  &.largeText {
    font: $label-16-med;
  }
}

.lowercase {
  display: inline-block;

  &::first-letter {
    text-transform: lowercase;
  }
}

.login {
  align-self: flex-start;
  background-color: transparent;
  border: none;
  color: $content-success;
  font: $label-14-reg;
  margin: $space-1 0 0;
  padding: 0;
  text-decoration: underline;
  white-space: nowrap;
}

.loginInline {
  font: $label-14-med;
  text-decoration: underline;
}

.loginUnderline {
  text-decoration: underline;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: $space-2;
}

.paginationTab {
  @include themify() {
    background-color: themed('contentTertiary');
  }

  border-radius: $border-radius-small;
  margin-right: $space-half;
  height: 2px;
  width: 16px;

  &:last-of-type {
    margin-right: 0;
  }

  &.isActive {
    @include themify() {
      background-color: themed('contentPrimary');
    }
  }
}

.iconCaret {
  display: none;
}

.rewardsBanner {
  @include themify() {
    border: 1px solid themed('borderPrimary');
  }

  align-items: center;
  background-color: $surface-primary;
  border-radius: $border-radius;
  box-sizing: border-box;
  cursor: auto;
  display: flex;
  justify-content: center;
  min-width: 100%;
  padding: $space-3 $space-2;
  position: relative;
  scroll-snap-align: center;
  width: calc(100vw - #{$space-4});
}

.fixedHeight {
  height: 112px;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .promoCarouselWrapper {
    overflow-x: hidden;
  }

  .bannerWrapper {
    flex: none;
    width: 100%;
  }

  .promoBanner {
    padding: $space-2;
    width: 100%;

    &.extraPadding {
      padding: $space-2 $space-5;
    }
  }

  .rewardsBanner {
    width: 100%;
    padding: $space-2;
  }

  .fixedHeight {
    height: $space-10;
  }

  .promoDescriptionWrapper {
    align-content: center;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    &.leftAlign {
      width: 100%;
    }
  }

  .promoDescription {
    display: block;
    font: $label-16-med;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .login {
    font: $label-16-reg;
    margin: 0 0 0 $space-2;
  }

  .iconCaret {
    display: block;
    left: 0;
    padding: $space-2;
    position: absolute;

    path {
      @include themify() {
        fill: themed('contentPrimary');
      }
    }

    &.iconRight {
      left: auto;
      transform: rotate(180deg);
      right: 0;
    }
  }
}
