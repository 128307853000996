@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.radioButtonWrapper {
  position: relative;
}

.radioButtonVisible {
  @include themify() {
    background-color: themed('surfacePrimary');
    border: 1px solid themed('borderPrimary');
  }

  align-items: center;
  border-radius: $border-radius-square;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: $space-2;
  top: 0;
  width: 100%;
}

.icon {
  height: $icon-large;
  width: $icon-large;
}

.swatch {
  border-radius: 50%;
  height: $icon-default;
  width: $icon-default;
}

.label {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  letter-spacing: $letter-spacing-large;
  margin-top: $space-half;
  text-align: center;
}

.sublabel {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-12-reg;
  margin-top: $space-half;
  text-align: center;
}

.noMargin {
  margin: 0;
}

.radioButtonInput {
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1;

  &:checked + .radioButtonVisible {
    @include themify() {
      border: 2px solid themed('borderTertiary');
    }
  }

  &:hover + .radioButtonVisible {
    @include themify() {
      background-color: themed('surfaceSecondary');
      border-color: themed('surfaceActive');
    }
  }

  &:focus + .radioButtonVisible {
    @include themify() {
      outline-color: themed('borderTertiary');
    }

    outline: 1px dashed;
    outline-offset: 1px;
  }

  &:disabled,
  &:disabled:hover {
    + .radioButtonVisible {
      @include themify() {
        background-color: themed('surfaceSecondary');
        border-color: themed('borderPrimary');
      }

      .label,
      .sublabel {
        @include themify() {
          color: themed('contentSecondary');
        }
      }
    }
  }
}
