@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Impact Score Modal Component Styles*/
/* ---------------------------------*/

.greeting {
  @include themify() {
    color: themed('contentInversePrimary') !important;
  }

  font: $headline-24-med;
  letter-spacing: $letter-spacing-headline-24;
}
