@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  display: flex;
  flex-direction: row;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.addressWrapperTop {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.addressWrapperBottom {
  display: flex;
  flex-direction: column;
}

.restaurantWrapper {
  display: flex;
}

.topDot {
  @include themify() {
    background: themed('contentTertiary');
  }

  border-radius: 64px;
  height: 8px;
  margin-left: -3px;
  margin-top: $space-half;
  width: 8px;
}

.bottomDot {
  @include themify() {
    background: themed('contentTertiary');
  }

  border-radius: 64px;
  height: 8px;
  margin-left: -3px;
  margin-top: $space-half;
  width: 8px;
}

.active {
  @include themify() {
    background-color: themed('contentActive');
  }
}

.topConnector {
  @include themify() {
    background: themed('borderPrimary');
  }

  border-radius: 1px;
  height: calc(100% - 16px);
  margin-right: 15px;
  margin-top: 4px;
  width: 2px;
}

.bottomConnector {
  height: 100%;
  margin-right: 15px;
  margin-top: 4px;
  width: 2px;
}

.header {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  margin-bottom: $space-1;
}

.address {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
}

.deliveryInstructions {
  font: $paragraph-14-italic;
  margin-top: $space-1;
  padding-left: 14px;
}
