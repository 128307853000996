@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Membership Checkout Component Styles*/
/* ---------------------------------*/

.membershipCheckout {
  @include themify() {
    color: themed('contentPrimary');
  }
}

.header {
  padding: $space-5 0;
  font: $headline-24-med;
}

.card {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  margin-bottom: $space-5;

  .cardTitle {
    @include themify() {
      border-bottom: solid 1px themed('borderPrimary');
    }

    font: $label-14-semi;
    padding: $space-3;
  }

  .cardContent {
    font: $paragraph-14-reg;
    padding: $space-3;
  }

  .contentWithIcon {
    align-items: center;
    display: flex;
    flex-direction: row;

    > svg {
      padding-right: $space-2;
      height: $space-3;
      width: $space-3;
    }
  }

  .contentWithBorder {
    @include themify() {
      border-bottom: solid 1px themed('borderPrimary');
    }

    padding-bottom: $space-3;
    margin-bottom: $space-3;
  }
}

.orderBtnWrapper {
  @include themify() {
    background-color: themed('surfacePrimary');
    border-top: solid 1px themed('borderPrimary');
  }

  bottom: 0;
  margin-top: $space-5;
  margin-left: -$space-2;
  padding: $space-2;
  position: fixed;
  width: calc(100% - #{$space-4});
  z-index: 1;
}

.tagWrapper {
  padding: 0 0 $space-2;
}

.footerWrapper {
  padding-bottom: 128px;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .rightRail {
    padding-top: $space-7;
  }

  .orderBtnWrapper {
    border: none;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
    margin-left: 0;
    padding: 0 0 $space-5;
    position: static;
    position: initial;
    width: 100%;
  }

  .tagWrapper {
    padding: $space-2 0 $space-5;
  }

  .centerTag {
    display: flex;
    justify-content: center;
  }

  .footerWrapper {
    padding-bottom: 0;
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .header {
    font: $headline-40-med;
  }

  .rightRail {
    grid-column-start: 9;
  }
}
