@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Order Item Component Styles*/
/* ---------------------------------*/

.itemWrapper {
  @include themify() {
    border-bottom: 1px solid themed('borderPrimary');
    color: themed('contentPrimary');
  }

  margin: $space-3;
  padding-bottom: $space-3;

  &:last-child {
    border: 0;
    padding: 0;
  }
}

.orderItem {
  display: flex;
}

.label {
  flex: 1;
  width: 180px;
}

.itemNamePriceWrapper {
  display: flex;
}

.itemName {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  padding-right: $space-1;
}

.editableItem {
  cursor: pointer;
}

.itemButtonContainer {
  display: flex;
  margin: $space-2 $space-2 0 $space-4;

  button {
    margin-right: $space-2;
  }
}

.quantity {
  align-items: baseline;

  @include themify() {
    color: themed('contentPrimary');
  }

  display: flex;
  font: $label-14-semi;
  justify-content: left;
  margin: 0 $space-1 0 0;
  min-width: 24px;
  text-align: center;
  width: 20px;
}

.price {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  margin-left: auto;
  text-align: right;
}

.modifier {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: inline;
  font: $paragraph-14-reg;
  padding: $space-half 0;
}

.specialInstructions {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $paragraph-14-italic;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.padTop {
  padding: $space-2 0 0;
}

.itemDetails {
  padding-top: $space-2;
}

.modName {
  display: inline;
}

.modPrice {
  padding-left: $space-half;
}
