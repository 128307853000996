@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Cateogry Modal Component Styles*/
/* ---------------------------------*/
.categoriesWrapper {
  p {
    font: $label-14-reg;
  }

  & button {
    padding-left: 0 !important; /* override component library*/
    padding-right: 0 !important; /* override component library*/
  }
}

.selected > button {
  @include themify() {
    background-color: themed('surfaceSecondary') !important;
  }
}
