@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.input {
  margin-top: $space-3;
}

.icon {
  path {
    @include themify() {
      fill: themed('contentSecondary') !important;
    }
  }
}

.secondaryButton {
  margin-top: $space-2;
}
