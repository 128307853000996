@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.restaurantName {
  @include themify() {
    color: themed('contentPrimary');
  }

  -webkit-box-orient: vertical;
  display: -webkit-box;
  font: $label-14-med;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
}

.nameWrapper {
  display: none;

  &.showOnMobile {
    display: flex;
  }

  &.noBorder {
    max-width: 180px;

    .restaurantName {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.address {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  text-align: left;
}

.addressWrapper {
  align-items: center;
  background-color: transparent;
  border: none;
  display: none;
  padding: 0;
  position: relative;

  &.noClick {
    pointer-events: none;
  }

  &.noWrap {
    overflow: hidden;

    .address {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.caretWrapper {
  display: flex;
  padding-right: $space-1;

  svg {
    transform-origin: center center;

    path {
      @include themify() {
        fill: themed('surfaceActive');
      }
    }
  }

  &.rotate {
    svg {
      transform: rotate(180deg);

      path {
        @include themify() {
          fill: themed('surfaceActive');
        }
      }
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .restaurantName {
    display: inline-block;
    font: $label-14-med;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nameWrapper {
    @include themify() {
      border-right: 1px solid themed('borderPrimary');
    }

    align-items: center;
    display: flex;
    height: $navbar-height;
    margin-right: $space-3;
    max-width: 140px;
    padding-right: $space-3;

    &.noBorder {
      border: none;
      margin: 0;
      max-width: 240px;
      padding: 0;

      &::after {
        background-color: $neutral-5;
        border-radius: 50%;
        content: '';
        display: inline-block;
        flex-shrink: 0;
        height: $space-half;
        margin: 0 $space-1;
        width: $space-half;
      }
    }

    .restaurantName {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: initial;
      white-space: normal;
      white-space: initial;
    }
  }

  .addressWrapper {
    display: flex;
  }

  .dropdownOverlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.3s;
    will-change: opacity;
    z-index: $z-index-nav + 1;

    &.visible {
      pointer-events: initial;
    }
  }
}

/* XLarge devices and above (1440px and up) */
@media (min-width: $app-container-width) {
  .restaurantName {
    font: $label-16-med;
  }

  .nameWrapper {
    max-width: 240px;

    .restaurantName {
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .address {
    font: $label-16-reg;
  }
}
