@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.container {
  @include themify() {
    border: 1px solid themed('borderPrimary');
  }

  border-radius: $border-radius;
  margin-top: $space-3;
}

.orderProgress {
  padding: $space-3;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orderId {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
}

.restaurantText {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
}

.bodyText {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
}

.estimatedText {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $paragraph-14-reg;
}

.orderTimeText {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-semi;
}

.divider {
  @include themify() {
    border-bottom: 1px solid themed('borderPrimary');
  }
}

.tableInfo {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  padding: $space-3 $space-2 $space-3 $space-3;
}

.fulfillmentDetails {
  @include themify() {
    color: themed('contentPrimary');
  }

  padding-top: $space-2;
}

.inlineBullet {
  margin-right: 12px;
  position: relative;

  &::after {
    @include themify() {
      background-color: themed('contentPrimary');
    }

    border-radius: 100%;
    content: '';
    height: 2px;
    position: absolute;
    right: -7px;
    top: 50%;
    width: 2px;
  }
}
