@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.notFoundWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  margin: $space-10 $space-2;
}

.image {
  height: auto;
  width: calc(100% - #{$space-4});
}

.headline {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-20-med;
  margin: $space-3 0;
  text-align: center;
}

.button {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
    color: themed('contentInversePrimary');
  }

  align-items: center;
  border-radius: $border-radius-round;
  display: flex;
  font: $label-16-med;
  height: $space-6;
  justify-content: center;
  padding: 0 $space-3;
  text-decoration: none;

  &:hover {
    @include themify() {
      background-color: themed('surfaceActive');
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .container {
    transform: translateY(-5%);
  }

  .image {
    width: auto;
  }

  .headline {
    font: $headline-24-med;
    margin: $space-5 0;
  }
}
