@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.orderTimeWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  margin: 0 $space-3;
  padding: $space-3 0;

  &:hover {
    > svg path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}

.orderTime {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;
}

.icon {
  display: inline-block;
  height: $space-3;
  margin-right: $space-3;
  width: $space-3;
}

.iconEdit {
  margin-left: $space-3;
  padding: 0;
}
