@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.downloadLink {
  display: none;
}

/* XLarge devices and above (1440px and up) */
@media (min-width: $app-container-width) {
  .downloadLink {
    @include themify() {
      color: themed('contentPrimary');
    }

    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    font: $label-16-reg;
    margin-right: $space-3;
    padding: 0;
  }

  .iconPhone {
    margin-right: $space-1;
  }
}
