@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Menu Category Item Modal Styles*/
/* ---------------------------------*/
.headingWrapper {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
  }

  align-items: center;
  background: $white;
  box-sizing: border-box;
  display: flex;
  height: $space-9;
  justify-content: space-between;
  min-height: $space-9;
  overflow: hidden;
  padding: 0 $space-2;
  position: sticky;
  top: 0;
  z-index: 99;
}

.itemHeader {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-20-semi;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.closeIcon {
  background: transparent;
  border: none;
  margin: -#{$space-2};
  padding: $space-2;

  svg {
    height: $icon-close-height;
    width: $icon-close-height;

    path {
      @include themify() {
        fill: themed('contentPrimary');
      }
    }
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .headingWrapper {
    padding: 0 $space-5;
  }
}
