@import '~styles/variables';

.iconSearch {
  height: $icon-height;
  width: $icon-height;
}

.searchInputWrap {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

input[type='search'].searchInput {
  border: none;
  border-radius: 0;
  padding: $space-2 $space-1;

  &::-webkit-search-cancel-button {
    display: none;
    pointer-events: none;
  }

  &.focus-visible {
    outline-offset: -2px;
  }

  &:focus-visible {
    outline-offset: -2px;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .searchInputWrap {
    padding-left: $space-2;
  }

  input[type='search'].searchInput {
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background: url(~images/icon-clear.svg) no-repeat 50% 50%;
      background-size: contain;
      cursor: pointer;
      display: inline;
      display: initial;
      height: $icon-height;
      margin-right: -8px;
      pointer-events: all;
      width: $icon-height;
    }

    &:focus::-webkit-search-cancel-button {
      opacity: 1;
    }
  }
}
