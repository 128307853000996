@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Download App Modal Component Styles*/
/* ---------------------------------*/

.content {
  @include themify() {
    color: themed('contentPrimary');
  }
}

.title {
  @include themify() {
    border-bottom: solid 1px themed('borderTertiary');
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
  margin-bottom: $space-3;
  padding-bottom: $space-3;
}

.desc {
  font: $paragraph-14-reg;
  margin-bottom: $space-3;
}

.qrCard {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  align-items: center;
  border-radius: $border-radius;
  display: flex;
  padding: $space-3 $space-2;
}

.qr {
  min-width: 100px;
}

.qrCall {
  font: $paragraph-14-reg;
  margin-left: $space-3;
}
