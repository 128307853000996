// Font Weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// Font Family
$font-family-base: 'Matter', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Letter Spacing
$letter-spacing-base: 0;
$letter-spacing-large: 0.2px;
$letter-spacing-headline-64: -1.6px;
$letter-spacing-headline-48: -1px;
$letter-spacing-headline-40: -0.6px;
$letter-spacing-headline-32: -0.4px;
$letter-spacing-headline-24: -0.2px;

// Type - Headline
$headline-64-med: $font-weight-medium 64px/64px $font-family-base;
$headline-48-med: $font-weight-medium 48px/52px $font-family-base;
$headline-40-med: $font-weight-medium 40px/44px $font-family-base;
$headline-32-med: $font-weight-medium 32px/36px $font-family-base;
$headline-24-med: $font-weight-medium 24px/28px $font-family-base;

// Type - Label
$label-20-reg: $font-weight-regular 20px/24px $font-family-base;
$label-20-med: $font-weight-medium 20px/24px $font-family-base;
$label-20-semi: $font-weight-semi-bold 20px/24px $font-family-base;
$label-16-reg: $font-weight-regular 16px/20px $font-family-base;
$label-16-med: $font-weight-medium 16px/20px $font-family-base;
$label-16-semi: $font-weight-semi-bold 16px/20px $font-family-base;
$label-14-reg: $font-weight-regular 14px/16px $font-family-base;
$label-14-med: $font-weight-medium 14px/16px $font-family-base;
$label-14-semi: $font-weight-semi-bold 14px/16px $font-family-base;
$label-12-reg: $font-weight-regular 12px/16px $font-family-base;
$label-12-med: $font-weight-medium 12px/16px $font-family-base;
$label-12-semi: $font-weight-semi-bold 12px/16px $font-family-base;

// Type - Paragraph
$paragraph-20-reg: $font-weight-regular 20px/28px $font-family-base;
$paragraph-20-italic: italic $font-weight-regular 20px/28px $font-family-base;
$paragraph-20-semi: $font-weight-semi-bold 20px/28px $font-family-base;
$paragraph-16-reg: $font-weight-regular 16px/24px $font-family-base;
$paragraph-16-italic: italic $font-weight-regular 16px/24px $font-family-base;
$paragraph-16-semi: $font-weight-semi-bold 16px/24px $font-family-base;
$paragraph-14-reg: $font-weight-regular 14px/20px $font-family-base;
$paragraph-14-italic: italic $font-weight-regular 14px/20px $font-family-base;
$paragraph-14-semi: $font-weight-semi-bold 14px/20px $font-family-base;

// Type - Caption
// to be used with text-transform: uppercase
$caption-12-med: $font-weight-medium 12px/16px $font-family-base;
$caption-10-med: $font-weight-medium 10px/16px $font-family-base;
