@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.entryWrapper {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  cursor: pointer;
  margin-top: $space-3;
  padding: $space-3;

  &:hover {
    @include themify() {
      background-color: themed('surfaceSecondary');
    }
  }
}

.tagWrapper > div {
  justify-content: center;
  width: 70px;
}

.orderDetails {
  margin-top: $space-2;
}

.restaurantName {
  @include themify() {
    color: themed('contentPrimary');
  }

  -webkit-box-orient: vertical;
  display: -webkit-box;
  font: $label-16-semi;
  -webkit-line-clamp: 1;
  margin-bottom: $space-1;
  overflow: hidden;
}

.orderItems {
  @include themify() {
    color: themed('contentPrimary');
  }

  -webkit-box-orient: vertical;
  display: -webkit-box;
  font: $paragraph-14-reg;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.progress {
  max-width: 400px;
}

.status {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $paragraph-14-reg;
}

.bold {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-semi;
  padding-left: $space-half;
}

.entryTotal {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
    color: themed('contentSecondary');
  }

  font: $paragraph-14-reg;
  margin-top: $space-2;
  padding-top: $space-2;
}

.orderDate {
  margin-right: $space-2;
  position: relative;

  &::after {
    @include themify() {
      background-color: themed('contentSecondary');
    }

    border-radius: 50%;
    content: '';
    height: 2px;
    position: absolute;
    right: -$space-1;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .entryWrapper {
    display: flex;
  }

  .orderDetails {
    flex-grow: 1;
    margin-left: $space-3;
    margin-top: 0;
  }
}
