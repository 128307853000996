@import '~styles/variables';

/* Restaurant Container Styles*/
/* ---------------------------------*/

.restaurantContent {
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$navbar-height});
}

.restaurantWrapper {
  box-sizing: border-box;
  min-height: calc(100vh - #{$footer-height + $navbar-height});

  &.withDisclaimer {
    min-height: calc(100vh - #{$footer-height + $navbar-height + $space-6});
  }

  &.noFooter {
    min-height: 0;
  }
}

.blurredMenu {
  filter: blur(9px);
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .restaurantContent {
    height: calc(100% - #{$navbar-height});
  }

  .restaurantWrapper,
  .restaurantWrapper.withDisclaimer {
    min-height: calc(100vh - #{$footer-height + $navbar-height});

    &.noFooter {
      min-height: 0;
    }
  }
}
