@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Vehicle Select Component Styles*/
/* ---------------------------------*/
.sectionTitle {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;
  font: $label-14-semi;
  justify-content: space-between;
  margin-bottom: $space-3;
}

.input {
  margin-top: $space-3;

  textarea {
    min-height: 104px !important; /* override component library*/
    padding: $space-2 !important; /* override component library*/
  }
}

.error {
  color: $content-caution;
  font: $label-12-reg;
  margin-top: $space-1;
}
