@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.fulfillmentWhen {
  position: relative;
  width: 100%;

  /* override component library*/
  > button {
    margin-top: $space-2;

    & > span {
      & > svg {
        min-width: 16px;
      }
    }
  }

  &.overrideBtnDisable {
    /* override component library*/
    @include themify() {
      background-color: themed('surfaceSecondary') !important;
      color: themed('contentPrimary') !important;
    }

    border: none !important;

    & > span > span > svg > path {
      @include themify() {
        fill: themed('contentPrimary') !important;
      }
    }
  }
}

.fulfillmentDetails {
  margin-left: $space-2;
  overflow: hidden;
  padding-left: $space-2;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;

  &::before {
    @include themify() {
      background-color: themed('borderPrimary');
    }

    top: 50%;
    content: '';
    height: 24px;
    margin-left: -$space-2;
    position: absolute;
    transform: translateY(-50%);
    width: 1px;
  }
}
