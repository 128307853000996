/* stylelint-disable no-descending-specificity */
@import '~styles/variables';
@import '~styles/mixins';
@import '~styles/functions';

.buttonIcon {
  align-items: center;
  border: none;
  border-radius: 28px;
  cursor: pointer;
  display: flex;
  height: $space-5;
  justify-content: center;
  padding: 12px;
  transition: background-color 0.3s, opacity 0.3s, border-color 0.3s;

  &:focus {
    @include themify() {
      outline-color: themed('borderTertiary');
    }

    outline: 1px dashed;
    outline-offset: 2px;
  }

  &.disabled {
    border: none;
    pointer-events: none;
  }
}

.fill {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
    color: themed('contentInversePrimary');
  }

  svg {
    path {
      @include themify() {
        fill: themed('contentInversePrimary');
      }
    }
  }

  &:hover {
    @include themify() {
      background-color: themed('surfaceActive');
    }
  }

  &.disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }

    svg {
      path {
        @include themify() {
          fill: themed('contentSecondary');
        }
      }
    }
  }
}

.outline {
  @include themify() {
    background-color: transparent;
    border: 1px solid themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-14-reg;

  svg {
    path {
      @include themify() {
        fill: themed('contentPrimary');
      }
    }
  }

  &:hover {
    @include themify() {
      border-color: themed('borderTertiary');
    }
  }

  &.disabled {
    @include themify() {
      background-color: themed('surfaceTertiary');
      color: themed('contentSecondary');
    }

    svg {
      path {
        @include themify() {
          fill: themed('contentSecondary');
        }
      }
    }
  }
}
