@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.heartsWrapper {
  margin-top: -40px; /* to accommodate top of gif*/
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.initials {
  /* One-off styles for the initials within the animation*/
  align-items: center;
  color: $white;
  display: flex;
  font-size: $headline-24-med;
  justify-content: center;
  left: 10px;
  opacity: 0;
  position: absolute;
  top: 71px;
  width: 83px;

  &.animated {
    animation: fadeIn;
    animation-delay: 1.25s;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
  }

  &.static {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.heartsAnimation {
  width: 160px;
}
