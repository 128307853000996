@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Credit Card Display Component Styles*/
/* ---------------------------------*/
.cardOption {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;
  flex: 1 0 auto;
}

.lastFour {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
}

.icon {
  padding-right: $space-1;

  g {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.btnRemove {
  font: $label-14-reg;
}

.invalid {
  color: $content-caution;
  font: $label-14-reg;
}
