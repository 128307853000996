@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Empty Bag Styles*/
/* ---------------------------------*/
.iconBag {
  height: 32px;
  margin: 0 auto;
  padding-top: $space-4;
  width: 32px;

  path {
    @include themify() {
      fill: themed('contentSecondary');
    }
  }
}

.emptyBag {
  text-align: center;
}

.message {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $label-16-reg;
  margin: 0 auto;
  max-width: 230px;
  padding: $space-2 $space-5 $space-5;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .iconBag {
    border-bottom: none;
  }
}
