@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Vehicle Display Component Styles*/
/* ---------------------------------*/
.curbsideWrapper {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  margin: 0 $space-3;
  padding: $space-3 0;

  &:hover {
    > svg path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}

.vehicleDetails {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;
  font: $label-14-reg;
}

.icon {
  display: inline-block;
  height: $space-3;
  margin-right: $space-3;
  width: $space-3;
}

.circle {
  border-radius: 50%;
  height: $space-2;
  margin-right: $space-1;
  width: $space-2;

  &.white {
    @include themify() {
      border: solid 1px themed('bordeSecondary');
    }
  }

  &.other {
    background-image: linear-gradient(to bottom, #000 0%, #818181 50%, #393939);
  }
}

.iconEdit {
  margin-left: $space-3;
  padding: 0;
}
