@import '~styles/variables';

.container {
  display: flex;
  flex-direction: column;
}

.fixed {
  padding: 0 $space-2;
  /* 400px column - 32px of padding*/
  flex-basis: 368px;
}

/* Medium devices and above (tablet, 768px and up) */
@media (min-width: $grid-breakpoint-m) {
  .container {
    flex-direction: row;
  }

  .fixed {
    /* 400px column - 48px of padding*/
    padding: 0 $space-3;
    flex-basis: 352px;
  }

  .map {
    flex-grow: 1;
  }
}
