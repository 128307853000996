@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* LocationListItem Component Styles*/
/* ---------------------------------*/
.locationsListItem {
  @include themify() {
    border: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  border-radius: $border-radius;
  font: $paragraph-14-reg;
  margin-bottom: $space-3;
  padding: $space-3;
  cursor: pointer;

  &:hover {
    @include themify() {
      background-color: themed('surfaceSecondary');
    }
  }
}

.restaurantName {
  font: $label-16-semi;
}

.address {
  margin: $space-1 0;
}

.fulfillmentType,
.orderAhead {
  margin-right: 10px;
  position: relative;

  &::after {
    @include themify() {
      background-color: themed('contentPrimary');
    }

    border-radius: 50%;
    content: '';
    height: 2px;
    position: absolute;
    right: -6px;
    top: 50%;
    width: 2px;
  }
}

.fulfillmentType:last-of-type {
  margin: 0;

  &::after {
    width: 0;
  }
}

.tagWrap {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  margin-top: $space-2;
  padding-top: $space-2;
}

.tag {
  color: $content-success;

  &.disabled {
    @include themify() {
      color: themed('contentSecondary');
    }
  }
}
