@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* AddressBar Component Styles*/
/* ---------------------------------*/
.searchInput {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius-square;
  width: 100%;
}

.searchInputWrap {
  position: relative;
}

.icon {
  height: $space-2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: $space-2;
}

.iconSearch {
  left: $space-2;

  &.active {
    path {
      @include themify() {
        fill: themed('contentPrimary');
      }
    }
  }
}

.iconClear {
  right: $space-2;
}
