@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Order Component Styles*/
/* ---------------------------------*/
.order {
  @include themify() {
    border: 1px solid themed('borderPrimary');
  }

  border-radius: $border-radius;
  margin: $space-3 0;

  &.isCheckout {
    margin: 0;
  }
}

.addItemsButton {
  padding-bottom: $space-3;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .menuOrder {
    @include themify() {
      border-bottom: none;
    }

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: $space-2 0 0 0;
  }

  .order {
    margin-top: $space-3;

    &.isCheckout {
      @include themify() {
        border-bottom: 1px solid themed('borderPrimary');
      }

      margin: 0;
    }
  }

  .addItemsButton {
    padding-bottom: $space-4;
  }
}
