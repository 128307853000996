@import '~styles/variables';

@mixin createGrid($size, $columns) {
  @for $i from 1 through $columns {
    &.#{$size}-#{$i} {
      grid-column-end: span $i;
    }

    &.#{$size}-offset-#{$i} {
      grid-column-start: col-start $i;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, [col-start] 1fr);
  grid-gap: $space-2;
  gap: $space-2;
  margin: 0 $space-2;

  @media screen and (min-width: $grid-breakpoint-m) {
    grid-template-columns: repeat(12, [col-start] 1fr);
    gap: $space-4;
    margin: 0 $space-4;
  }

  @media screen and (min-width: $grid-breakpoint-l) {
    gap: $space-7;
    margin: 0 $space-7;
  }

  @media screen and (min-width: $grid-breakpoint-max) {
    margin: 0 auto;
    max-width: $grid-max-width;
  }
}

.gridItem {
  @include createGrid('sm', 4);

  @media screen and (min-width: $grid-breakpoint-m) {
    @include createGrid('md', 12);
  }

  @media screen and (min-width: $grid-breakpoint-l) {
    @include createGrid('lg', 12);
  }
}
