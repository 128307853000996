@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Membership Order Summary Component Styles*/
/* ---------------------------------*/

.membershipOrderSummary {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  margin-bottom: $space-5;
}

.orderSummaryHeader {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
  }

  font: $label-14-semi;
  padding: $space-3;
}

.orderSummaryContent {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
  }

  padding: $space-3;
}

.orderItem {
  display: flex;
  font: $label-14-semi;

  .itemQuantity {
    display: flex;
    flex-basis: 28px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .itemName {
    padding-right: $space-1;
    width: 100%;
  }

  .itemPrice {
    display: flex;
    width: $space-7;
    justify-content: flex-end;
  }
}

.hideableContent {
  padding-top: $space-2;
  margin-left: 28px;
}

.modifier {
  font: $paragraph-14-reg;
}

.editBtn {
  display: block;
  margin-top: $space-2;
}

.receipt {
  padding: $space-3;
}

.receiptItem {
  display: flex;
  font: $label-14-reg;
  justify-content: space-between;
  padding-bottom: $space-2;
}

.total {
  display: flex;
  font: $label-16-semi;
  justify-content: space-between;
}
