@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.toast {
  @include themify() {
    background-color: themed('surfacePrimary');
    border: 1px solid themed('borderPrimary');
    color: themed('contentPrimary');
  }

  border-radius: $space-1;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  display: flex;
  font: $paragraph-14-reg;
  padding: $space-2;
  width: 100%;
}

.iconCloseWrapper {
  background: none;
  border: none;
  display: flex;
  margin-bottom: auto;
  margin-left: auto; /* required to push icon to the right of flexbox*/
  padding: 0 0 $space-1 0;
}

.iconClose {
  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.title {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  padding-right: $space-half;
}

.message {
  margin: $space-1 $space-1 0 0;
}

.link {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-reg;
  margin-top: $space-1;
  text-decoration: underline;

  &:hover,
  &:active {
    @include themify() {
      color: themed('contentActive');
    }
  }

  &:focus,
  &.focus-visible {
    @include themify() {
      outline: thin dashed themed('contentPrimary');
    }
  }

  &:focus,
  &:focus-visible {
    @include themify() {
      outline: thin dashed themed('contentPrimary');
    }
  }
}

.toastButton {
  @include themify() {
    color: themed('contentPrimary');
  }

  background: none;
  border: 0;
  font: $label-14-reg;
  padding: 0;
  text-decoration: underline;

  &:hover,
  &:active {
    @include themify() {
      color: themed('contentActive');
    }

    cursor: pointer;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $grid-breakpoint-m) {
  .toast {
    max-width: 328px;
  }
}
