@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.carouselWrapper {
  display: flex;
  margin: 0 0 $space-3;
  overflow-x: auto;
  position: relative;
}

.item {
  margin-right: $space-2;
  position: relative;
  width: 256px;

  &:first-child {
    margin-left: $space-2;
  }
}

.iconWrapper {
  display: none;
}

.buttonWrapper {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .buttonWrapper {
    display: block;
  }

  .section {
    position: relative;
  }

  .showArrows {
    opacity: 1;
  }

  .carouselWrapper {
    margin: $space-3 0 0 0;
    overflow-x: hidden;
    padding-bottom: $space-5;
    position: relative;
  }

  .item {
    &:first-child {
      margin-left: 0;
    }
  }

  .iconWrapper {
    @include themify() {
      background-color: themed('surfaceInversePrimary');
      border: solid 1px themed('borderPrimary');
    }

    align-items: center;
    border-radius: $border-radius-round;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
    display: flex;
    height: $space-5;
    justify-content: center;
    left: -($space-2);
    position: absolute;
    top: $space-7;
    transition: background-color 0.3s;
    width: $space-5;

    &.right {
      left: auto;
      right: -($space-2);
      transform: rotate(180deg);
      z-index: 1;
    }

    &.disabled {
      display: none;
    }

    &:hover {
      @include themify() {
        background-color: themed('surfaceActive');
      }
    }
  }
}

/* large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .carouselWrapper {
    display: flex;
  }

  .item {
    margin-right: $space-2;
    /* 12px is gap between each menu card minus border*/
    min-width: calc((100% / 3) - 12px);

    &:last-of-type {
      margin-right: 0;
    }
  }
}

/* X-large devices and above (1440px and up) */
@media (min-width: $screen-xl-min) {
  .item {
    flex-grow: 1;
    min-width: 277px;
  }

  .twoItemsStyling {
    flex-grow: 0;
  }
}
