@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.modalheader {
  margin-bottom: $space-3;
}

.title {
  font: $label-20-semi;

  &.withSubtitle {
    margin-bottom: $space-1;
  }
}

.subtitle {
  font: $paragraph-14-reg;
}

.stickyHeader {
  @include themify() {
    background-color: themed('surfacePrimary');
    border-bottom: solid 1px themed('borderPrimary');
  }

  box-sizing: border-box;
  display: none;
  margin-left: -$space-2;
  padding: $space-3 $space-5 $space-3 $space-2;
  position: fixed;
  width: 100vw;
  z-index: 1;

  &.isVisible {
    display: block;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $grid-breakpoint-m) {
  .stickyHeader {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: -$space-5;
    padding: $space-3 $space-5;
    width: 100%;
  }
}
