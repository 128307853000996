@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Bag Summary Items Component Styles*/
/* ---------------------------------*/
.wrapper {
  @include themify() {
    border-top: 1px solid themed('borderPrimary');
  }

  display: grid;
  grid-row-gap: $space-2;
  padding: $space-3;
}

.item {
  display: flex;
  font: $label-14-reg;
}

.label {
  flex: 1 0 auto;
  position: relative;
}

.itemValue {
  text-align: right;
}

.total {
  font: $label-16-semi;
  text-transform: none;
  text-transform: initial;
}

.description {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-12-reg;
  padding-top: $space-1;
}

.actionableLink {
  @include themify() {
    color: themed('contentPrimary');
  }

  text-decoration: underline;
}

.toolTipTitle {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-med;
  letter-spacing: 0;
  text-transform: capitalize;
}

.subtotal {
  @include themify() {
    border-top: 1px solid themed('borderPrimary');
    color: themed('contentPrimary');
  }

  display: flex;
  font: $label-16-semi;
  justify-content: space-between;
  padding: $space-3;
}
