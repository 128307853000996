@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Mod Category Styles*/
/* ---------------------------------*/

.modWrapper {
  padding-bottom: $space-3;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.mod {
  @include themify() {
    color: themed('contentSecondary');
  }

  align-items: center;
  cursor: pointer;
  display: flex;
}

.modName {
  @include themify() {
    color: themed('contentPrimary');
  }

  cursor: pointer;
  flex: 1;
  margin: 0 $space-5 0 $space-2;
}

.price {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: flex;
  text-align: right;

  svg {
    align-items: center;
    justify-content: center;
    padding-left: $space-half;
  }
}

.disabledText {
  opacity: 0.4;
}

.banner {
  @include themify() {
    background-color: themed('surfaceSecondary');
    color: themed('contentPrimary');
  }

  border-radius: $border-radius-square;
  font: $label-14-reg;
  padding: $space-2 $space-2 $space-2 $space-5;
  margin-top: $space-1;
}

.modList {
  padding-bottom: $space-1;
}
