/* stylelint-disable no-descending-specificity */
@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Fulfillment Picker Component Styles*/
/* ---------------------------------*/
.fulfillmentOptions {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 (-$space-2);
  padding: $space-3 $space-2 $space-5;

  &.checkout {
    align-items: flex-start;
    background: $white;
    margin: 0;
    padding: 0 0 $space-5;
  }
}

.isBusy {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: flex;
  font: $label-12-reg;
  margin: $space-2 0 0 0;
}

.iconCaution {
  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }

  height: $icon-height;
  margin-right: $space-1;
  width: $space-2;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .fulfillmentOptions {
    border-bottom: none;
    border-top: none;
    margin: 0;
    padding: 0 0 $space-2 0;
  }

  .isBusy {
    margin: $space-2 0 0;
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .checkout {
    width: 350px;
  }
}
