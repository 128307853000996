@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .dropdownWrapper {
    @include themify() {
      background-color: themed('surfacePrimary');
      border: solid 1px themed('borderPrimary');
    }

    border-radius: $border-radius-square;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    /* Screen height - (nav height + header height + bottom margin)*/
    max-height: calc(100vh - #{$navbar-height + $space-5 + $space-8});
    margin: $space-2 0;
    overflow: scroll;
    position: absolute;
    text-align: left;
    top: $space-2;
    width: 300px;
    z-index: $z-index-nav + 2;
  }
}
