@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.tableWrapper {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  background: none;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
}

.tableHeader {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  font: $label-16-semi;
  padding: $space-3;
  text-align: left;
}

.tableDetails {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  background: none;
  border: none;
  display: flex;
  font: $paragraph-14-reg;
  justify-content: space-between;
  padding: $space-3;

  &:hover {
    > svg path {
      @include themify() {
        fill: themed('contentActive');
      }
    }
  }
}

.instructions {
  @include themify() {
    color: themed('contentSecondary');
  }

  font: $paragraph-14-italic;
}

.tableError {
  color: $content-caution;
  font: $paragraph-14-italic;
}

.iconEdit {
  margin-left: $space-3;
  padding: 0;
}
