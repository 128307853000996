@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.label {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: block;
  font: $label-14-reg;
  margin-bottom: $space-1;
}

.error {
  color: $content-caution;
  font: $label-12-reg;
  margin-top: $space-1;
}

.input {
  margin-top: $space-3;
}
