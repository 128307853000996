@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.wrapper {
  @include themify() {
    border-color: themed('borderPrimary');
    color: themed('contentPrimary');
  }
}

.confirmationWrapper {
  @include themify() {
    border: 1px solid themed('borderPrimary');
  }

  border-radius: $border-radius;
}

.promoWrapper {
  max-height: $space-12;
  padding: 0 $space-2 $space-2;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .scrollWrapper {
    flex: 1 1 auto;
    max-height: calc(100vh - #{430px + $footer-height});
    overflow-y: auto;

    &.withErrors {
      max-height: calc(100vh - #{510px + $footer-height});
    }

    &.noScroll {
      max-height: none;
      max-height: initial;
    }

    &.withDisclaimer {
      max-height: calc(100vh - #{430px + $disclaimer-height + $footer-height});
    }
  }

  .gradient {
    background: linear-gradient(0deg, $white, rgba(255, 255, 255, 0));
    height: $space-3;
    margin-top: -#{$space-3};
    opacity: 0.8;
    width: 100%;
  }
}
