@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Menu Disclaimer Container Styles*/
/* ---------------------------------*/

.wrapper {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  cursor: pointer;
  padding: $space-2;
  position: relative;
}

.disclaimerContent {
  align-items: flex-start;
  display: flex;
}

.iconInfo {
  height: $space-2;
  margin-right: $space-1;
  padding-top: 2px;
  width: $space-2;

  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.disclaimer {
  @include themify() {
    color: themed('contentPrimary');
  }

  letter-spacing: 0; /* one off as react-truncate miscalculates with letter-spacing*/
  line-height: 20px;
  width: calc(100% - #{$space-5});

  .more {
    @include themify() {
      color: themed('contentPrimary');

      &:hover {
        color: themed('contentActive');
      }
    }

    text-decoration: underline;
  }
}

.underline {
  text-decoration: underline;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .wrapper {
    padding: $space-2 $space-4;

    &.clearMargin {
      margin-top: 0;
    }
  }

  .disclaimerContent {
    margin: 0 auto;
  }

  .iconInfo {
    height: $icon-height;
    margin-top: 2px;
    padding: 0;
    width: $icon-height;
  }
}

/* Large devices and above (tablet, 1440px and up) */
@media (min-width: $app-container-width) {
  .wrapper {
    padding: $space-2 $space-7;
  }
}
