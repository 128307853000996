@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.footer {
  background-color: $surface-primary;
  border-top: 1px solid $border-primary;
  display: flex;
  padding: $space-3 $space-2;
}

.footerContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logoWrapper {
  display: none;
}

.textOutlinks {
  display: flex;
  flex-direction: column;
  gap: $space-3;

  a {
    font: $label-12-reg;
    text-decoration: none;
  }
}

.socialLinks {
  margin-top: $space-3;
  display: flex;
  gap: $space-2;
}

.mobileLogo {
  border-top: 1px solid $border-primary;
  margin-top: $space-3;
  padding-top: $space-3;
}

.socialIcon {
  &:hover,
  &:active {
    > svg path {
      @include themify() {
        fill: themed('contentActive');
      }

      fill: $content-active;
      text-decoration: none;
      transition: fill 0.2s ease;
    }
  }
}

@media screen and (min-width: $grid-breakpoint-m) {
  .footer {
    padding: $space-4;
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $space-4;

    svg {
      height: $space-3;
      width: auto;
    }
  }

  .textOutlinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $space-2;
  }

  .mobileLogo {
    display: none;
  }
}

@media screen and (min-width: $grid-breakpoint-l) {
  .footer {
    padding: $space-4 $space-7;
  }

  .footerContent {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .textOutlinks {
    align-items: center;
  }

  .logoWrapper {
    margin: 0;
  }

  .socialLinks {
    margin-top: 0;
  }
}
