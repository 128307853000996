@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.toggleContainer {
  @include themify() {
    background-color: themed('surfaceInversePrimary');
  }

  border-radius: $border-radius-round;
  height: $space-6;
  position: absolute;
}
