@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Tip Options Component Styles*/
/* ---------------------------------*/

/* FIXME: have to nest because of component library issues CN-23127*/
.tipOptions {
  /* Override component library styling*/
  gap: $space-1 !important;
  grid-template-columns: repeat(5, [col-start] 1fr) !important;
  margin: 0 !important;
}

.radioWrapper {
  label {
    padding: $space-2 $space-1;
  }

  &.other {
    min-width: 48px;

    label > div {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

/* X Small devices and below (350px and below) */
@media (max-width: $screen-xs-max) {
  .tipOptions {
    gap: $space-half !important;
  }

  .radioWrapper {
    label {
      padding: $space-2 $space-half;
    }
  }
}
