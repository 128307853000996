@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Google Map Container Styles*/
/* ---------------------------------*/

.mapIframe {
  height: 220px;
  position: relative;
  width: 100%;
}

.googleMapWrap {
  @include themify() {
    background-color: themed('surfaceTertiary');
  }

  border-radius: inherit;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  div:first-of-type {
    z-index: 0;
  }
}

.mapError {
  @include themify() {
    color: themed('contentTertiary');
  }

  align-items: center;
  display: flex;
  font: $label-16-semi;
  justify-content: center;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .disabled {
    pointer-events: none;
  }
}
