@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.inputWrapper {
  position: relative;
  width: 100%;
}

.label {
  display: block;
  text-align: left;

  &.hideLabel {
    display: none;
  }
}

.input {
  @include themify() {
    color: themed('contentPrimary');
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius-square;
  box-sizing: border-box;
  display: flex;
  font: $paragraph-16-reg;
  margin-top: $space-1;
  min-height: $space-10;
  padding: 11px $space-2;
  width: 100%;

  &:-ms-input-placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &::placeholder {
    @include themify() {
      color: themed('contentSecondary');
    }
  }

  &[disabled] {
    @include themify() {
      background-color: themed('$surface-tertiary');
      color: themed('contentSecondary');
    }

    &:hover,
    &:focus {
      @include themify() {
        border-color: themed('borderPrimary');
      }
    }
  }
}

.characterLimit {
  @include themify() {
    color: themed('contentSecondary');
  }

  color: $content-secondary;
  display: block;
  font: $caption-10-med;
  letter-spacing: 0.8px;
  margin-top: $space-1;
  text-align: right;
  width: 100%;
}
