@import '~styles/variables';

.emptyMenu {
  color: $neutral-5;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.emptyCaption {
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  display: flex;
  flex-direction: column;
  font: $label-20-reg;
  height: 100%;
  left: 0;
  padding: $space-10 $space-2;
  position: absolute;
  right: 0;
  z-index: 1;
}

.blurredMenu {
  filter: blur(9px);
}

.iconMenu {
  height: $space-7;
  margin: 0 auto $space-2;
  width: $space-7;
}

.tempCloseCaption {
  font: $label-20-semi;
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .emptyCaption {
    font: $headline-24-med;
  }

  .iconMenu {
    height: $space-10;
    width: $space-10;
  }

  .tempCloseCaption {
    margin: 0 auto;
    max-width: 700px;
  }
}
