@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Membership Plans Container Styles*/
/* ---------------------------------*/
.header {
  @include themify() {
    background-color: themed('surfaceSecondary');
  }

  padding: $space-7 0;
}

.heading {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-32-med;
}

.description {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $paragraph-14-reg;
  padding-top: $space-7;
}

.restaurantInfo {
  @include themify() {
    border-top: solid 1px themed('borderPrimary');
  }

  font: $paragraph-14-reg;
  margin-top: $space-3;
  padding-top: $space-5;
}

.locationName {
  font: $paragraph-14-semi;
  padding-top: $space-1;
}

.address {
  font: $paragraph-14-semi;
}

.cardSection {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
  }

  padding: $space-5 0;
}

.membershipCard {
  @include themify() {
    border: solid 1px themed('borderPrimary');
  }

  border-radius: $border-radius;
  margin-bottom: $space-1;
}

.membershipHeader {
  @include themify() {
    border-bottom: solid 1px themed('borderPrimary');
    color: themed('contentPrimary');
  }

  display: flex;
  font: $label-14-semi;
  justify-content: space-between;
  padding: $space-2 $space-3;

  .membershipTitle {
    display: flex;
    align-items: center;
  }

  .tagAndBadge {
    display: flex;
    align-items: center;

    > svg {
      padding-left: $space-1;
    }
  }
}

.privacyPolicy {
  padding: $space-5 0 $space-7;

  > div > div {
    padding-top: 0;
    text-align: left;
  }
}

.membershipContent {
  display: flex;

  .firstContent {
    @include themify() {
      border-right: solid 1px themed('borderPrimary');
    }

    margin: $space-3 0;
    padding: 0 $space-3;
    width: 50%;
  }

  .secondContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $space-3;
    width: 50%;
  }
}

.buyNowBtn {
  width: 120px;
}

.percent {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
}

.percentCta {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-24-med;
}

.price {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-14-semi;
  padding-bottom: $space-1;
}

.sectionHeader {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $headline-32-med;
  padding-bottom: $space-3;
}

.outlinks {
  @include themify() {
    color: themed('contentSecondary');
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .description {
    padding-top: $space-5;
  }

  .cardSection {
    padding: $space-7 0;
  }

  .restaurantInfo {
    @include themify() {
      border-top: none;
    }

    margin-top: 0;
    border-top: none;
    padding-top: 0;
  }

  .membershipCard {
    margin-bottom: -$space-1;
  }

  .percent {
    font: $label-20-med;
  }

  .percentCta {
    font: $label-20-med;
  }

  .membershipContent {
    .firstContent {
      align-items: center;
      display: flex;
      flex: 1;
    }

    .secondContent {
      width: -moz-fit-content;
      width: fit-content;
    }
  }

  .sectionHeader {
    padding-bottom: $space-1;
  }
}

/* Large devices and above (1200px and up) */
@media (min-width: $screen-lg-min) {
  .membershipCard {
    margin-bottom: 0;
  }

  .description {
    font: $paragraph-16-reg;
    padding-top: $space-7;
  }

  .locationName {
    font: $paragraph-16-semi;
  }

  .address {
    font: $paragraph-16-semi;
  }

  .cardSection {
    padding: $space-10 0;
  }

  .membershipHeader {
    padding: $space-3;
  }

  .membershipContent {
    flex-direction: column;

    .firstContent {
      @include themify() {
        border-bottom: solid 1px themed('borderPrimary');
        border-right: none;
      }

      flex-direction: column;
      margin: 0 $space-5;
      padding: $space-5 0 $space-3;
      width: auto;
    }

    .secondContent {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $space-3 $space-5 $space-5;
      width: auto;
    }
  }

  .percent {
    font: $headline-64-med;
  }

  .percentCta {
    font: $label-16-semi;
  }

  .price {
    font: $label-16-semi;
    padding-bottom: $space-2;
  }

  .sectionHeader {
    font: $headline-48-med;
    padding-bottom: 0;
  }

  .privacyPolicy {
    padding: $space-10 0;
  }
}
