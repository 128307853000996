@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

/* Side Menu Component Styles*/
/* ---------------------------------*/

.sideMenu {
  background: $white;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding: 0 $space-3;
}

.sideMenuWrap {
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  width: 320px;
  z-index: $z-index-side-menu;
}

.hamburgerIcon {
  height: 20px;
  margin: 0 0 -2px 0;
  top: $space-3;
  width: $space-3;

  path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.closeIcon {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 6px;
  top: 20px;

  svg path {
    @include themify() {
      fill: themed('contentPrimary');
    }
  }
}

.header {
  @include themify() {
    border-bottom: solid 1px themed('borderTertiary');
  }
}

.membershipStatus {
  align-items: flex-start;
  display: flex;
  padding: $space-5 0 $space-3;
}

.greeting {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  display: flex;
  flex: 1;
  font: $label-16-reg;
  letter-spacing: 0;
  line-height: 20px;
  min-height: $space-3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.greetingText {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.linkWrap {
  padding-top: $space-3;
}

.listItem {
  @include themify() {
    color: themed('contentPrimary');
  }

  cursor: pointer;
  font: $label-16-med;
  padding-bottom: $space-3;

  &:hover {
    @include themify() {
      color: themed('surfaceActive');
    }
  }

  &.restaurantLink {
    @include themify() {
      border-top: 1px solid themed('borderPrimary');
      padding-top: $space-3;
    }
  }
}

.overlay {
  background-color: rgba(24, 24, 24, 0.64);
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: opacity;
  z-index: $z-index-side-menu - 1;

  &.touchable {
    pointer-events: all;
  }
}

.listLink {
  @include themify() {
    color: themed('contentPrimary');
  }

  font: $label-16-med;
  text-decoration: none;

  &:visited {
    @include themify() {
      color: themed('contentPrimary');
    }
  }

  &:hover {
    @include themify() {
      color: themed('surfaceActive');
    }
  }
}

.hamburgerIconBtn {
  background: none;
  border: none;
  padding: 0;
}

.downloadWrapper {
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: $space-3;
}

.orderCopy {
  @include themify() {
    color: themed('contentPrimary');
  }

  font-weight: $font-weight-medium;
}

.orderLink {
  @include themify() {
    color: themed('contentPrimary');
  }

  display: block;
  font-weight: $font-weight-light;
  text-decoration: underline !important; /*bootstrap override*/
}

.appLinkWrapper {
  align-items: center;
  display: flex;
}

.iconsWrapper {
  @include themify() {
    border-top: 1px solid themed('borderPrimary');
  }

  margin-top: $space-3;
  padding-top: $space-3;
}

.appLogoWrapper {
  height: $space-6;
  margin-right: $space-2;
}

/* X Small devices and below (350px and below) */
@media (max-width: $screen-xxs-max) {
  .listItem {
    padding: $space-1 0;
  }
}
