@import '~styles/mixins';
@import '~styles/functions';
@import '~styles/variables';

.findMoreRestaurants {
  @include themify() {
    color: themed('contentPrimary');
  }

  align-items: center;
  border: none;
  color: $content-primary;
  background-color: transparent;
  display: flex;
  font: $label-14-reg;
  justify-content: center;
  padding: 0;

  > svg {
    padding-right: $space-1;

    > path {
      @include themify() {
        fill: themed('contentPrimary');
      }

      fill: $content-primary;
    }
  }

  &:active,
  &:hover {
    @include themify() {
      outline-color: themed('contentPrimary');
    }

    outline: $content-primary dashed 1px;
  }
}

/* Small devices and above (tablet, 768px and up) */
@media (min-width: $screen-sm-min) {
  .findMoreRestaurants {
    font: $label-16-reg;
  }
}
